import { Component, OnInit, Input, ViewChild, ElementRef, ViewChildren, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, FormGroupDirective, Validators, ControlContainer } from '@angular/forms';
import { CommonHelperService } from 'src/app/services/common-helper.service';
import { commonDropdownConfig } from 'src/app/models/bank-payment.model';
@Component({
  selector: 'org-chain-reusable',
  templateUrl: './org-chain-reusable.component.html',
  styleUrls: ['./org-chain-reusable.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class OrgChainReusableComponent implements OnInit {
  @Input() isDomainRequired;
  @Input() requiredCheckForChainTypeOnly;
  @Input() requiredCheck;
  @Input() boInOrgTypeList;
  @Input() dropDownDisableForSingleListValue;
  @Input() domainIdData;
  @ViewChild('domainDiv') private domainDiv: ElementRef;
  @ViewChild('masterAgentDiv') private masterAgentDiv: ElementRef;
  @ViewChild('agentDiv') private agentDiv: ElementRef;
  @ViewChild('subAgentDiv') private subAgentDiv: ElementRef;
  @ViewChild('retailerDiv') private retailerDiv: ElementRef;
  @ViewChild('chainTypeDiv') private chainTypeDiv: ElementRef;
  orgTypeCode = localStorage.getItem('orgTypeCode')
  form: FormGroup;
  public configDropdown: commonDropdownConfig;
  configDomain;
  configOrgType;
  constructor(private ctrlContainer: FormGroupDirective, private formBuilder: FormBuilder,
    private commonHelper: CommonHelperService) {
    this.commonHelper.shouldResetOrgChain.subscribe(res => {
      if (res) {
        this.resetPage();
        this.commonHelper.callNgOnInit(false);
      }
    })  
    //ngx-dropdown place holder values by lang 
    this.configDropdown = new commonDropdownConfig();
    this.configDomain = this.configDropdown.configDomain;
    this.configOrgType = this.configDropdown.configOrgType;
  }
  domainNameList = [];
  orgTypeList = [];
  chainTypeList = [];
  masterAgentList = []
  agentList = [];
  subAgentList = []
  retailerList = [];
  boOrgId;
  loggedInOrgId = localStorage.getItem('orgId');
  get formControl() {
    return (<FormGroup>this.form.controls['orgChainForm'])
  }

  ngOnInit() {
    this.form = this.ctrlContainer.form;
    this.form.removeControl('orgChainForm');
    this.form.addControl('orgChainForm',
      this.formBuilder.group({
        'domainId': ['',Validators.required],
        'orgType': [""],
        'chainType': [""],
        'masterAgent': [""],
        'agent': [""],
        'subAgent': [""],
        'retailer': [""],
      }));


      this.orgTypeList = [];
      this.chainTypeList = [];
      this.masterAgentList = [];
      this.agentList = [];
      this.subAgentList = [];
      this.retailerList = [];

    // this.controlValues = { controlName: 'domainId', data:'', disable: false, enable: true,emitEvent:false }

    if (this.isDomainRequired.domainRequired) {
      const sendToken = {
        token: localStorage.getItem('authToken'),
        domainId: localStorage.getItem('accessSelfDomainOnly') == 'YES' ? localStorage.getItem('domainId') : 'ALL'
      };
      this.commonHelper.makeRequest(sendToken, 'getDomainList', true).subscribe(res => {
        if (res.statusCode == 0) {
          this.domainNameList = res.data;
          if(this.domainIdData) {
            this.controlValues = { controlName: 'domainId', data: this.domainNameList.filter(e => e.domainId == this.domainIdData.domainId)[0], disable: false, enable: true, event: false }               
          }
          if (this.domainNameList.length == 1) {
            this.controlValues = { controlName: 'domainId', data: this.domainNameList[0], disable: true, enable: false }
            if (this.isDomainRequired.hideDiv)
              this.domainDiv.nativeElement.remove();
          }/*else{
            this.controlValues = { controlName: 'domainId', data: '', disable: false, enable: true }
          }*/
        }
      });
      // this.formControl.controls['domainId'].setValidators([Validators.required]);
      // this.formControl.controls['domainId'].updateValueAndValidity({emitEvent:false})
    } else {
      this.domainNameList = [{ domainId: localStorage.getItem('accessSelfDomainOnly') == 'YES' ? localStorage.getItem('domainId') : 'ALL', domainName: 'SKILROCK' }]
      this.controlValues = { controlName: 'domainId', data: this.domainNameList[0], disable: true, enable: false }
      this.domainDiv.nativeElement.remove();
    }

    this.formControl.get('domainId').valueChanges.subscribe(domainId => {
      this.hideAllDiv();
      if (domainId && domainId.domainId) {
        const data = {
          token: localStorage.getItem('authToken'),
          domainId: domainId.domainId
        }
        if(this.orgTypeCode == 'SUPER_BO'){
          this.commonHelper.makeRequest(data, 'getBoOrgs', false).subscribe(res => {
            if (res.statusCode == 0) {
              this.boOrgId = res.data;
            }
          })
        }
        
        this.commonHelper.makeRequest(data, 'getOrgTypeList', false).subscribe(res => {
          if (res.statusCode == 0) {
            this.orgTypeList = [];
            for (let key in res.data) {
              if (this.boInOrgTypeList ||  key != 'BO')
                this.orgTypeList.push({ orgTypeCode: key, orgTypeName: res.data[key] })
            }
            if (this.dropDownDisableForSingleListValue && this.orgTypeList.length == 1) {
              this.controlValues = { controlName: 'orgType', data: this.orgTypeList[0], disable: true, enable: false }
            } else {
              this.controlValues = { controlName: 'orgType', data: '', disable: false, enable: true }
            }
          }
        })
      }
    })

    this.formControl.get('orgType').valueChanges.subscribe(orgType => {
      this.hideAllDiv();
      this.removeRequired(['masterAgent', 'agent', 'subAgent','retailer'])
      this.controlValues={controlName:'masterAgent',data:'',disable:false, emitEvent:false}
      this.controlValues={controlName:'agent',data:'',disable:false, emitEvent:false}
      this.controlValues={controlName:'subAgent',data:'',disable:false, emitEvent:false}
      this.controlValues={controlName:'retailer',data:'',disable:false, emitEvent:false}
      this.controlValues={controlName:'chainType',data:'',disable:false, emitEvent:false}
      if (orgType.orgTypeCode) {
        let data = {
          token: localStorage.getItem('authToken'),
          orgTypeCode: '',
          parentOrgId: '',
          parentOrgTypeCode: 'BO',
          domainId: this.formControl.get('domainId').value.domainId,
          searchedOrgTypeCode:this.formControl.get('orgType').value.orgTypeCode

        }

        if (this.orgTypeCode == "SUPER_BO" || this.orgTypeCode == "BO") {
          data.parentOrgId = (this.orgTypeCode == 'SUPER_BO')?this.boOrgId:this.loggedInOrgId
          }

        switch (orgType.orgTypeCode) {
          case 'MAGT':
            this.makeRequired(['masterAgent'])
            if (this.orgTypeCode == "SUPER_BO" || this.orgTypeCode == "BO") {
              this.controlValues = { controlName: 'masterAgent', data: '', disable: false, enable: true }
              data.orgTypeCode = 'MAGT'
              this.getMasterAgentList(data);
            } else {
              this.masterAgentList = [{ orgName: localStorage.getItem('orgName'), orgId: localStorage.getItem('orgId') }]
              this.controlValues = { controlName: 'masterAgent', data: this.masterAgentList[0], disable: true }
            }
            this.showHideDiv([{ field: this.masterAgentDiv, show: true },
            { field: this.agentDiv, show: false },
            { field: this.subAgentDiv, show: false },
            { field: this.retailerDiv, show: false },
            { field: this.chainTypeDiv, show: false }
            ])
            break;
          case 'AGT':
            this.controlValues = { controlName: 'masterAgent', data: '', disable: false, enable: true, emitEvent: false }
            this.showHideDiv([{ field: this.masterAgentDiv, show: true },
            { field: this.agentDiv, show: true },
            { field: this.subAgentDiv, show: false },
            { field: this.retailerDiv, show: false },
            { field: this.chainTypeDiv, show: false }
            ])
            if (this.orgTypeCode == "SUPER_BO" || this.orgTypeCode == "BO") {
              this.makeRequired(['masterAgent', 'agent'])
              data.orgTypeCode = 'MAGT'
              this.getMasterAgent(data);
            }
            else if (this.orgTypeCode == 'MAGT') {
              this.makeRequired(['masterAgent', 'agent'])
              this.masterAgentList = [{ orgName: localStorage.getItem('orgName'), orgId: localStorage.getItem('orgId') }]
              this.controlValues = { controlName: 'masterAgent', data: this.masterAgentList[0], disable: true }
            }
            else {
              this.makeRequired(['agent'])
              this.showHideDiv([{ field: this.masterAgentDiv, show: false },
              ])
              this.agentList = [{ orgName: localStorage.getItem('orgName'), orgId: localStorage.getItem('orgId') }]
              this.controlValues = { controlName: 'agent', data: this.agentList[0], disable: true }
            }
            break;
          case 'SAGT':
            this.controlValues = { controlName: 'masterAgent', data: '', disable: false, enable: true, event: false }
            this.controlValues = { controlName: 'agent', data: '', disable: false, enable: true, event: false }
            this.showHideDiv([{ field: this.masterAgentDiv, show: true },
            { field: this.agentDiv, show: true },
            { field: this.subAgentDiv, show: true },
            { field: this.retailerDiv, show: false },
            { field: this.chainTypeDiv, show: false }
            ])
            if (this.orgTypeCode == "SUPER_BO" || this.orgTypeCode == "BO") {
              this.makeRequired(['masterAgent', 'agent', 'subAgent'])
              data.orgTypeCode = 'MAGT'
              this.getMasterAgent(data);
            }
            else if (this.orgTypeCode == 'MAGT') {
              this.makeRequired(['masterAgent', 'agent', 'subAgent'])
              this.masterAgentList = [{ orgName: localStorage.getItem('orgName'), orgId: localStorage.getItem('orgId') }]
              this.controlValues = { controlName: 'masterAgent', data: this.masterAgentList[0], disable: true }
            }
            else if (this.orgTypeCode == 'AGT') {
              this.showHideDiv([{ field: this.masterAgentDiv, show: false },
              ])
              this.makeRequired(['agent', 'subAgent'])
              this.agentList = [{ orgName: localStorage.getItem('orgName'), orgId: localStorage.getItem('orgId') }]
              this.controlValues = { controlName: 'agent', data: this.agentList[0], disable: true }
            }
            else {
              this.showHideDiv([{ field: this.masterAgentDiv, show: false }, { field: this.agentDiv, show: false },])
              this.makeRequired(['subAgent'])
              this.subAgentList = [{ orgName: localStorage.getItem('orgName'), orgId: localStorage.getItem('orgId') }]
              this.controlValues = { controlName: 'subAgent', data: this.subAgentList[0], disable: true }
            }
            break;
          case 'RET':
              const chainTypeRequest = {
                token: localStorage.getItem('authToken'),
                domainId: this.formControl.get('domainId').value.domainId,
                orgTypeCode: this.orgTypeCode
              }
              this.commonHelper.makeRequest(chainTypeRequest, 'getChainType', false).subscribe(res => {
                this.chainTypeList = [];
                if (res.statusCode == 0) {
                  this.chainTypeList = res.data
                  if ( this.dropDownDisableForSingleListValue && this.chainTypeList.length == 1) {
                    this.controlValues = { controlName: 'chainType', data: this.chainTypeList[0], disable: true }
                  }
                }
              })
            this.controlValues = { controlName: 'chainType', data: '', disable: false, emitEvent: false }
            this.showHideDiv([{ field: this.masterAgentDiv, show: false },
            { field: this.agentDiv, show: false },
            { field: this.subAgentDiv, show: false },
            { field: this.retailerDiv, show: false },
            { field: this.chainTypeDiv, show: true }
            ])
            break;
          default:
            this.showHideDiv([{ field: this.masterAgentDiv, show: false },
            { field: this.agentDiv, show: false },
            { field: this.subAgentDiv, show: false },
            { field: this.retailerDiv, show: false },
            { field: this.chainTypeDiv, show: false }
            ])
            break;

        }
      } else {
        this.hideAllDiv();
        this.removeRequired(['masterAgent', 'subAgent', 'agent', 'retailer', 'chainType'])
        this.masterAgentList=[];
        this.agentList=[];
        this.subAgentList=[];
        this.retailerList=[];
      }

    })


    this.formControl.get('masterAgent').valueChanges.subscribe(masterAgent => {
      this.controlValues = { controlName: 'retailer', data: '', disable: false, enable: true, emitEvent: false }
      if (masterAgent && masterAgent.orgId) {
        let data = {
          token: localStorage.getItem('authToken'),
          orgTypeCode: 'AGT',
          parentOrgId: masterAgent.orgId,
          parentOrgTypeCode: 'MAGT',
          domainId: this.formControl.get('domainId').value.domainId,
          searchedOrgTypeCode:this.formControl.get('orgType').value.orgTypeCode
        }
        if (this.formControl.get('orgType').value.orgTypeCode == 'RET' && this.formControl.get('chainType').value.chainTypeCode == 'MAGT') {
          data.orgTypeCode = 'RET'
          this.getImmediateChild(data);

        }
        else if (this.formControl.get('orgType').value.orgTypeCode != 'MAGT') {
          data['searchedOrgTypeCode'] = this.formControl.get('orgType').value.orgTypeCode;
          this.commonHelper.makeRequest(data, 'getOrgList', false).subscribe(res => {
            this.controlValues = { controlName: 'agent', data: '', disable: false, enable: true, emitEvent: false }
            this.agentList = [];
            if (res.statusCode == 0) {
              this.agentList = res.data;
              if (this.agentList.length == 1) {
                this.controlValues = { controlName: 'agent', data: this.agentList[0], disable: true, enable: false }
              }
            }
          })
        }
      }else{
         this.controlValues = { controlName: 'agent', data: '', disable: false, enable: true, emitEvent: false }
         this.controlValues = { controlName: 'subAgent', data: '', disable: false, enable: true, emitEvent: false }
         this.controlValues = { controlName: 'retailer', data: '', disable: false, enable: true, emitEvent: false }  
         this.agentList=[];
         this.subAgentList=[];
         this.retailerList=[];
        }
    })

    this.formControl.get('agent').valueChanges.subscribe(agent => {
      this.controlValues = { controlName: 'retailer', data: '', disable: false, enable: true, emitEvent: false }
      if (agent && agent.orgId) {
        let data = {
          token: localStorage.getItem('authToken'),
          orgTypeCode: 'SAGT',
          parentOrgId: agent.orgId,
          parentOrgTypeCode: 'AGT',
          domainId: this.formControl.get('domainId').value.domainId,
          searchedOrgTypeCode:this.formControl.get('orgType').value.orgTypeCode
        }
        if (this.formControl.get('orgType').value.orgTypeCode == 'RET' && this.formControl.get('chainType').value.chainTypeCode == 'MAGT_AGT') {
          data.orgTypeCode = 'RET';
          this.getImmediateChild(data);
        }
        else if (this.formControl.get('orgType').value.orgTypeCode != 'AGT') {
          data['searchedOrgTypeCode'] = this.formControl.get('orgType').value.orgTypeCode;
          this.commonHelper.makeRequest(data, 'getOrgList', false).subscribe(res => {
            this.controlValues = { controlName: 'subAgent', data: '', disable: false, enable: true, emitEvent: false }
            this.subAgentList = [];
            if (res.statusCode == 0) {
              this.subAgentList = res.data;
              if (this.subAgentList.length == 1) {
                this.controlValues = { controlName: 'subAgent', data: this.subAgentList[0], disable: true, enable: false }
              }
            }
          })
        }
      }else{
        this.controlValues = { controlName: 'subAgent', data: '', disable: false, enable: true, emitEvent: false }
        this.controlValues = { controlName: 'retailer', data: '', disable: false, enable: true, emitEvent: false }  
        this.subAgentList=[];
        this.retailerList=[];
      }
    })


    this.formControl.get('subAgent').valueChanges.subscribe(subAgent => {
      this.controlValues = { controlName: 'retailer', data: '', disable: false, enable: true, emitEvent: false }
      if (subAgent && subAgent.orgId) {
        let data = {
          token: localStorage.getItem('authToken'),
          orgTypeCode: 'RET',
          parentOrgId: subAgent.orgId,
          parentOrgTypeCode: 'SAGT',
          domainId: this.formControl.get('domainId').value.domainId,
          searchedOrgTypeCode:this.formControl.get('orgType').value.orgTypeCode
        }
        if (this.formControl.get('orgType').value.orgTypeCode == 'RET' && this.formControl.get('chainType').value.chainTypeCode == 'MAGT_AGT_SAGT') {
          data.orgTypeCode = 'RET';
          this.getImmediateChild(data);
        }
      }else{
        this.controlValues = { controlName: 'retailer', data: '', disable: false, enable: true, emitEvent: false }  ;
        this.retailerList=[];
      }
    })

    this.formControl.get('chainType').valueChanges.subscribe(chainType => {
      this.removeRequired(['masterAgent', 'agent', 'subAgent','retailer']);
      if (chainType.chainTypeCode) {
        this.showHideDiv([{ field: this.masterAgentDiv, show: false },
        { field: this.agentDiv, show: false },
        { field: this.subAgentDiv, show: false },
        { field: this.retailerDiv, show: true },
        { field: this.chainTypeDiv, show: true }
        ])
     
        let data = {
          token: localStorage.getItem('authToken'),
          orgTypeCode: 'RET',
          parentOrgId: '',
          domainId: this.formControl.get('domainId').value.domainId,
          searchedOrgTypeCode:this.formControl.get('orgType').value.orgTypeCode,
        }
        if (this.orgTypeCode == "SUPER_BO" || this.orgTypeCode == "BO") {
          data.parentOrgId = (this.orgTypeCode == 'SUPER_BO')?this.boOrgId:this.loggedInOrgId;
          }
          
        switch (chainType.chainTypeCode) {
          case 'DIRECT':
            this.makeRequired(['retailer'])
            this.controlValues = { controlName: 'retailer', data: '', disable: false, enable: true, emitEvent: false };
            this.getImmediateChild(data);
            break;
          case 'MAGT':
            this.controlValues = { controlName: 'masterAgent', data: '', disable: false, enable: true, emitEvent: false };
            this.controlValues = { controlName: 'retailer', data: '', disable: false, enable: true, emitEvent: false }
            this.showHideDiv([{ field: this.masterAgentDiv, show: true },
            { field: this.agentDiv, show: false },
            { field: this.subAgentDiv, show: false },
            { field: this.retailerDiv, show: true },
            { field: this.chainTypeDiv, show: true }
            ])
            this.makeRequired(['retailer','masterAgent'])

            if (this.orgTypeCode == "SUPER_BO" || this.orgTypeCode == "BO") {
              data.orgTypeCode = 'MAGT';
              data['searchedOrgTypeCode'] = this.formControl.get('orgType').value.orgTypeCode;
              this.commonHelper.makeRequest(data, 'getImmediateChildOrgList', false).subscribe(res => {
                this.masterAgentList = [];
                if (res.statusCode == 0) {
                  this.masterAgentList = res.data;
                  if (this.masterAgentList.length == 1) {
                    this.controlValues = { controlName: 'masterAgent', data: this.masterAgentList[0], disable: true, enable: false }
                  }
                }
              });
            } else {
              this.masterAgentList = [{ orgName: localStorage.getItem('orgName'), orgId: localStorage.getItem('orgId') }]
              this.controlValues = { controlName: 'masterAgent', data: this.masterAgentList[0], disable: true, emitEvent: false }
              this.getImmediateChild(data)
            }
            break;
          case 'MAGT_AGT':
            this.controlValues = { controlName: 'masterAgent', data: '', disable: false, enable: true, emitEvent: false }
            this.controlValues = { controlName: 'agent', data: '', disable: false, enable: true, emitEvent: false }
            this.controlValues = { controlName: 'retailer', data: '', disable: false, enable: true, emitEvent: false }
            this.showHideDiv([{ field: this.masterAgentDiv, show: true },
            { field: this.agentDiv, show: true },
            { field: this.subAgentDiv, show: false },
            { field: this.retailerDiv, show: true },
            { field: this.chainTypeDiv, show: true }
            ])
            this.makeRequired(['retailer','masterAgent','agent'])

            if (this.orgTypeCode == "SUPER_BO" || this.orgTypeCode == "BO") {
              data.orgTypeCode = 'MAGT'
              this.masterAgentList = [];
              this.getMasterAgentList(data);
            } else if (this.orgTypeCode == 'MAGT') {
              this.masterAgentList = [{ orgName: localStorage.getItem('orgName'), orgId: localStorage.getItem('orgId') }]
              this.controlValues = { controlName: 'masterAgent', data: this.masterAgentList[0], disable: true }
            } else {
              this.showHideDiv([{ field: this.masterAgentDiv, show: false }]);
              this.agentList = [{ orgName: localStorage.getItem('orgName'), orgId: localStorage.getItem('orgId') }]
              this.controlValues = { controlName: 'agent', data: this.agentList[0], disable: true }
              this.getImmediateChild(data)
            }
            break;
          case 'MAGT_AGT_SAGT':
            this.controlValues = { controlName: 'masterAgent', data: '', disable: false, enable: true, emitEvent: false }
            this.controlValues = { controlName: 'agent', data: '', disable: false, enable: true, emitEvent: false }
            this.controlValues = { controlName: 'subAgent', data: '', disable: false, enable: true, emitEvent: false }
            this.controlValues = { controlName: 'retailer', data: '', disable: false, enable: true, emitEvent: false }
            this.showHideDiv([{ field: this.masterAgentDiv, show: true },
            { field: this.agentDiv, show: true },
            { field: this.subAgentDiv, show: true },
            { field: this.retailerDiv, show: true },
            { field: this.chainTypeDiv, show: true }
            ])
            this.makeRequired(['retailer','masterAgent','agent','subAgent'])
            if (this.orgTypeCode == "SUPER_BO" || this.orgTypeCode == "BO") {
              data.orgTypeCode = 'MAGT'
              this.masterAgentList = [];
              this.getMasterAgentList(data);
            } else if (this.orgTypeCode == 'MAGT') {
              this.masterAgentList = [{ orgName: localStorage.getItem('orgName'), orgId: localStorage.getItem('orgId') }]
              this.controlValues = { controlName: 'masterAgent', data: this.masterAgentList[0], disable: true }
            } else if (this.orgTypeCode == 'AGT') {
              this.agentList = [{ orgName: localStorage.getItem('orgName'), orgId: localStorage.getItem('orgId') }]
              this.controlValues = { controlName: 'agent', data: this.agentList[0], disable: true }
            }
            else {
              this.showHideDiv([{ field: this.masterAgentDiv, show: false }, { field: this.agentDiv, show: false }])
              this.subAgentList = [{ orgName: localStorage.getItem('orgName'), orgId: localStorage.getItem('orgId') }]
              this.controlValues = { controlName: 'subAgent', data: this.subAgentList[0], disable: true }
              this.getImmediateChild(data)
            }
            break;
        }
      } else {
        this.controlValues = { controlName: 'retailer', data: '', disable: false, enable: true, emitEvent: false }
        this.showHideDiv([{ field: this.masterAgentDiv, show: false },
        { field: this.agentDiv, show: false },
        { field: this.subAgentDiv, show: false },
        { field: this.retailerDiv, show: false },
        { field: this.chainTypeDiv, show: true }
        ])
      }
    })
    this.hideAllDiv();
  }

  getImmediateChild(data) {
    this.controlValues = { controlName: 'retailer', data: '', disable: false, enable: true, emitEvent: false }
    this.retailerList = [];
    data['searchedOrgTypeCode'] = this.formControl.get('orgType').value.orgTypeCode;
    this.commonHelper.makeRequest(data, 'getImmediateChildOrgList', false).subscribe(res => {
      if (res.statusCode == 0) {
        this.retailerList = res.data
        if ( this.dropDownDisableForSingleListValue && this.retailerList.length == 1) {
          this.controlValues = { controlName: 'retailer', data: this.retailerList[0], disable: true, enable: false }

        }
      }
    });
  }

  
  makeRequired(fieldName) {
    for (let i = 0; i < fieldName.length; i++) {
      if(this.requiredCheck && this.requiredCheckForChainTypeOnly && this.formControl.controls['chainType'].value){
      this.formControl.controls[fieldName[i]].setValidators([Validators.required]);
      this.formControl.controls[fieldName[i]].updateValueAndValidity({ emitEvent: false })
    }
  }
  }

  removeRequired(fieldName) {
    for (let i = 0; i < fieldName.length; i++) {
      this.formControl.controls[fieldName[i]].clearValidators();
      this.formControl.controls[fieldName[i]].updateValueAndValidity({ emitEvent: false });
    }
  }

  hideAllDiv() {
    this.showHideDiv([{ field: this.masterAgentDiv, show: false },
    { field: this.agentDiv, show: false },
    { field: this.subAgentDiv, show: false },
    { field: this.retailerDiv, show: false },
    { field: this.chainTypeDiv, show: false }
    ])
  }

  showHideDiv(controls) {
    controls.forEach(element => {
      if (element.show)
        element.field.nativeElement.style.display = 'block';
      else element.field.nativeElement.style.display = 'none';
    });
  }

  set controlValues(value) {
    this.formControl.patchValue({ [value.controlName]: value.data, emitEvent: value.emitEvent });
    if (value.disable)
      this.formControl.get(value.controlName).disable({ emitEvent: false });
    if (value.enable)
      this.formControl.get(value.controlName).enable({ emitEvent: false });
  }

  getMasterAgent(data) {
    data['searchedOrgTypeCode'] = this.formControl.get('orgType').value.orgTypeCode;
    this.commonHelper.makeRequest(data, 'getOrgList', false).subscribe(res => {
      if (res.statusCode == 0) {
        this.masterAgentList = res.data;
        if (this.masterAgentList.length == 1) {
          this.controlValues = { controlName: 'masterAgent', data: this.masterAgentList[0], disable: true, enable: false };
        }
      }
    })
  }

  getMasterAgentList(data) {
    data['searchedOrgTypeCode'] = this.formControl.get('orgType').value.orgTypeCode;
    this.commonHelper.makeRequest(data, 'getImmediateChildOrgList', false).subscribe(res => {
      if (res.statusCode == 0) {
        this.masterAgentList = res.data;
        if (this.masterAgentList.length == 1) {
          this.controlValues = { controlName: 'masterAgent', data: this.masterAgentList[0], disable: true, enable: false };
        }
      }
    });
  }


  resetPage() {
    this.ngOnInit();
    this.formControl.get('domainId').markAsUntouched({onlySelf:true});
    this.formControl.get('orgType').patchValue('',{emitEvent:false})
  }

}
