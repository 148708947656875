import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchPosPipe'
})
export class SearchPosPipePipe implements PipeTransform {
    transform(array: any, query: string): any {
        if (query != undefined && query) {
            return array.filter(row => {
                if (row.configDescription == null)
                    row.configDescription = ""
                const posType = row.orgType.toLowerCase().includes(query.toLowerCase())
                const appType = row.orgName.toLowerCase().includes(query.toLowerCase())
                const model = row.emailId.toLowerCase().includes(query.toLowerCase())
                const geoTrackingMode = row.mobileNumber.toLowerCase().includes(query.toLowerCase())
                return (posType + appType + model + geoTrackingMode);
            }
            )
        }
        return array;
    }
}