import { Component, OnInit } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { CommonHelperService } from './services/common-helper.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  constructor(private router:Router,private commonHelper:CommonHelperService){
    this.commonHelper.makeRequest({},'getConfigValues',false).subscribe(res=>{

      this.commonHelper.setMobileCode(res.data ? res.data.COUNTRY_CODES.split(',') : []);
      this.commonHelper.setMobileCodeMinMax(res.data ? Object.keys(res.data.MOBILE_CODE_MIN_MAX_LENGTH).map(x=>{ return {[x] :res.data.MOBILE_CODE_MIN_MAX_LENGTH[x]}}) : []);
      this.commonHelper.setAllowdedLanguages(res.data ? res.data.SYSTEM_ALLOWED_LANGUAGES : '');

    })
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
      this.blockUI.start('Loading...');
      } else if (event instanceof RouteConfigLoadEnd) {
        this.blockUI.stop();
      }
  });
  
  }
  title = 'RMSFrontEnd';
  ngOnInit(){
    this.commonHelper.setLogosConfiguration()
    // this.router.navigate(['RMS']);
    

  }
  ngDoCheck(){
    this.commonHelper.setUserName(localStorage.getItem('username'))
  }
}
