import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './shared/components/login/login.component';
import { AuthGuard } from './services/auth.guard';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import {PasswordComponent} from './shared/components/password/password.component';

const routes: Routes = [
  // { path: 'pdf', component:PdfComponent},
  { path: '', redirectTo: 'RMS', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'RMS', loadChildren: './rms/rms.module#RMSModule', canActivate: [AuthGuard] },
  { path: 'forgotPassword', component :ForgotPasswordComponent },
  {path: 'change_password', component: PasswordComponent},
  // { path: '**', redirectTo: 'RMS' },
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
