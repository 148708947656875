import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './shared/components/login/login.component';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpInterceptorService} from './services/http-interceptor.service';
import {AuthGuard} from './services/auth.guard';
import {SharedModule} from './shared/shared.module';
import { AppTranslationModule } from './app-translation.module';
import { IframeUrlService } from './services/iframeUrlService';
import {NgxCaptchaModule} from 'ngx-captcha';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    AppTranslationModule,
    FormsModule,
    NgxCaptchaModule

  ],
  providers: [ HttpInterceptorService, {
    provide : HTTP_INTERCEPTORS,
    useClass : HttpInterceptorService,
    multi : true
  }, AuthGuard,
  IframeUrlService
],
  
  bootstrap: [AppComponent]
})
export class AppModule {
}
