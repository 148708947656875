import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonHelperService } from 'src/app/services/common-helper.service';
import { environment } from 'src/environments/environment';
import { CustomValidators } from '../../directives/custom-validator';
declare var $: any;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassword: FormGroup
  selectedLang = localStorage.getItem('lang') || 'en';
  timer = "02:00"
  responseMessage;
  enableResendOTP=false
  mobileCode;
  loginHeaderLogo
  loginFooterLogo
  showPage = 'forgotPassword'
  showLangButtons=environment.SHOWLANGBUTTON;
  showButton: Boolean = false;
  showEye: Boolean = false;
  showButtonConfirm: Boolean = false;
  showEyeConfirm: Boolean = false;
  passwordRegexMessage;
  messages;
  passwordRegex;
  mobileMaxLength;
  mobileMinLength;
  mobileCodeMinMax;
  mblCode;
  messagePassRegex;
  allowdedLanguages = '';
  otpLength: any;
  constructor(private fb: FormBuilder, private commonHelper:CommonHelperService) { 
    this.commonHelper.setLogosConfiguration()

    this.commonHelper.getMOBILE_CODE_MIN_MAX_LENGTH.subscribe(mobileCodeMinMax=>{
      this.mobileCodeMinMax=mobileCodeMinMax;
      if(this.mblCode) this.updateMobileLengths();
    })
    this.commonHelper.loginHeaderLogo.subscribe(res=>{
     this.loginHeaderLogo=res
   })
   this.commonHelper.loginFooterLogo.subscribe(res=>{
     this.loginFooterLogo=res;
   })
   this.commonHelper.getSYSTEM_ALLOWED_LANGUAGES.subscribe(res=>{
    this.allowdedLanguages = res;
    let langList = res ? res.split(',') : [];
    this.showLangButtons = false;
    if(langList[0]){
      this.showLangButtons = true;
    }
  })
  }

  ngOnInit() {
    this.forgotPassword = this.fb.group({
      userName: ['', CustomValidators.required],
      mobileNo: ['', CustomValidators.required],
      OTP:['',CustomValidators.required],
      newPassword:[null],
      confirmPassword:[null],
      mobileCode:[]
    })

    this.commonHelper.makeRequest({},'getConfigValues',false).subscribe(res=>{
      this.passwordRegex = res.data.PASSWORD_REGEX;
      this.otpLength = res.data.OTP_LENGTH;
      if (this.passwordRegex){
        [,this.messagePassRegex] = this.commonHelper.checkRegex(this.passwordRegex);
        this.forgotPassword.controls.newPassword.setValidators([Validators.pattern(this.passwordRegex)]);
        this.forgotPassword.controls.newPassword.updateValueAndValidity();
        this.forgotPassword.controls.confirmPassword.setValidators([Validators.pattern(this.passwordRegex)]);
        this.forgotPassword.controls.confirmPassword.updateValueAndValidity();
      }
    })

    this.forgotPassword.valueChanges.subscribe(res=>{
      this.responseMessage=''
    })

    this.forgotPassword.get('mobileCode').valueChanges.subscribe(mobileCode=>{
      this.mblCode = mobileCode;
      if(this.mobileCodeMinMax) this.updateMobileLengths();
    })

    setTimeout(()=>{
      this.commonHelper.getMOBILECODE.subscribe(mobileCode=>{
        this.mobileCode=mobileCode;
        this.forgotPassword.get('mobileCode').patchValue(this.mobileCode[0]);
      })
      [this.passwordRegexMessage,this.messages] =  this.commonHelper.checkRegex(environment.PASSWORD_REGEX);
    },100)

  }

  updateMobileLengths(){
    let mobileData =  (this.mobileCodeMinMax).filter(data=>Object.keys(data)==this.mblCode)
    this.mobileMinLength= mobileData[0][Object.keys(mobileData[0])[0]].min || '8';
    this.mobileMaxLength= mobileData[0][Object.keys(mobileData[0])[0]].max || '10';
  }

  setLang(lang) {
    this.selectedLang = lang
    localStorage.setItem('lang', lang);
    $(window).trigger("changeLang", []);
  }

  setClass(field) {
    if (field == 'userName') {
      this.setUserNameClass = true
    }
    if (field == 'mobileNo') {
      this.setMobileNoClass = true
    }
  }
  removeClass(field) {
    if (field == 'userName' && !this.forgotPassword.get('userName').value) {

      this.setUserNameClass = false
    }
    if (field == 'mobileNo' && !this.forgotPassword.get('mobileNo').value) {
      this.setMobileNoClass = false
    }
  }
  setUserNameClass
  setMobileNoClass

  sendOTP() {
    this.responseMessage=''
    if(this.forgotPassword.get('userName').valid && this.forgotPassword.get('mobileNo').valid){
      const data={
        userName: this.forgotPassword.get('userName').value,
        mobileCode: this.forgotPassword.get('mobileCode').value,
        mobileNumber: this.forgotPassword.get('mobileNo').value
      }
    this.commonHelper.initiateForgotPassword(data).subscribe(res => {
      if(res.statusCode==0){
        this.showPage = 'enterOTP'
        this.setTimer()
      }
      else{
        this.responseMessage = res.message;
      }
      
    },error=>{
      this.responseMessage= this.commonHelper.getCustomMessages('somethingWentWrong');
    })
  }else{
    this.validateAllFormFields(this.forgotPassword)
  }
  }


  submitOTP() {
    if(this.forgotPassword.valid){
      if(this.forgotPassword.get('confirmPassword').value != this.forgotPassword.get('newPassword').value){
        this.responseMessage = this.commonHelper.getCustomMessages('new&ConfirmPasswordDoNotMatch');
        return;
      }
      const data={
          "confirmNewPassword": this.forgotPassword.get('confirmPassword').value,
          "mobileCode": this.forgotPassword.get('mobileCode').value,
          "mobileNumber": this.forgotPassword.get('mobileNo').value,
          "newPassword":  this.forgotPassword.get('newPassword').value,
          "otp":  this.forgotPassword.get('OTP').value,
          "userName":  this.forgotPassword.get('userName').value    
      }
      this.commonHelper.resetPassword(data).subscribe(res=>{
        if(res.statusCode==0){
        this.showPage = 'newPassword'
        }else{
          this.responseMessage=res.message
        }
      })
    }else{
      this.validateAllFormFields(this.forgotPassword);
    }
  }

 

 validateAllFormFields(formGroup: FormGroup) {  
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);
      if (control instanceof FormControl) {             //{4}
        control.markAsDirty({ onlySelf: true }); 
        control.markAsTouched({ onlySelf: true });       
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
}

  setTimer() {
    this.timer="02:00"
    this.enableResendOTP=false;
    let interval = setInterval(() => {
      let timer = this.timer.split(':');
      let minutes:any = (timer[0]);
      let seconds :any = (timer[1]);
      seconds  -= 1;
      if (minutes < 0) return;
      else if (seconds < 0 && minutes != 0) {
        minutes -= 1;
        seconds = 59;
      }
      else if (seconds < 10 && seconds.toString.length != 2) seconds = '0' + seconds;

      this.timer = (minutes + ':' + seconds);

      if (minutes == 0 && seconds == 0) 
      {
        this.enableResendOTP=true;
        clearInterval(interval)
      };
    }, 1000);
  }

  showPassword(type){
    if(type=='password'){
      this.showButton = !this.showButton;
      this.showEye = !this.showEye;
    }else{
       this.showButtonConfirm = !this.showButtonConfirm;
      this.showEyeConfirm = !this.showEyeConfirm;
    }
  }
}
