import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  domain = environment.URL_Node

  constructor(private http: HttpClient) {}

  sentenceCase(camelCaseText) : string{
    var text             = camelCaseText;
    var result           = text.replace( /([A-Z])/g, " $1" );
    var sentenceCaseText = result.charAt(0).toUpperCase() + result.slice(1);
    return sentenceCaseText;
  }

  returnDynamicSelectData(dataKeys) : string[]{
    var returnArray = [];

    for(var i=0; i < dataKeys.length; i++){
      returnArray.push({keyValue : dataKeys[i],keyName : this.sentenceCase(dataKeys[i])})
    }

    return returnArray;
  }

}
