import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchOrgPipe'
})
export class SearchOrgPipePipe implements PipeTransform {

  transform(array: any, query: string): any {


    if (query != undefined && query) {
      return array.filter(row => {
        if (!row.configDescription) {
          row.configDescription = ""
        }
        // const orgType = row.orgType.toLowerCase().includes(query.toLowerCase())      // TypeError: Cannot read properties of null (reading 'toLowerCase')
        // const orgName = row.orgName.toLowerCase().includes(query.toLowerCase())
        // const emailId = row.emailId.toLowerCase().includes(query.toLowerCase())
        // const mobileNumber = row.mobileNumber.toLowerCase().includes(query.toLowerCase())
        const orgType = (row.orgType || "").toLowerCase().includes(query.toLowerCase());
        const orgName = (row.orgName || "").toLowerCase().includes(query.toLowerCase());
        const emailId = (row.emailId || "").toLowerCase().includes(query.toLowerCase());
        const mobileNumber = (row.mobileNumber || "").toLowerCase().includes(query.toLowerCase());
        return (orgType + orgName + emailId + mobileNumber);
      }
      )
    }
    return array;
  }
}
