import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonHelperService } from 'src/app/services/common-helper.service';


@Component({
  selector: 'app-update-search-domain-modal',
  templateUrl: './update-search-domain-modal.component.html',
  styleUrls: ['./update-search-domain-modal.component.scss']
})
export class UpdateSearchDomainModalComponent implements OnInit {

  updateDomainForm: FormGroup;
  selectData;
  selectPersonalContent;
  selectDomainContent;
  countryList;
  stateList;
  cityList;
  domainId;
  domainName;
  errorMessage;
  responseMessage;
  selectedField: any;
  zipCodeRegex;
  domainRequest = {
    token: localStorage.getItem('authToken')
  };
  inputdomainData;
  domainData;
  selectTypeArray = [];
  countryCodes = [];
  mobileMaxLength;
  domainNameList;
  mobileMinLength;
  mobileMinMaxLength;
  constructor(private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private commonHelper: CommonHelperService,
  ) {
    this.commonHelper.getMOBILE_CODE_MIN_MAX_LENGTH.subscribe(mobileCodeMinMax => {
      this.mobileMinMaxLength = mobileCodeMinMax;
    })
  }

  get updateType() {
    return this.updateDomainForm.get('updateType');
  }

  ngOnInit() {
    this.updateDomainForm = this.fb.group({
      updateType: ['', Validators.required],
      selectedField: ['', Validators.required],
      fieldValue: [''],
      addressOne: [''],
      addressTwo: [''],
      country: [''],
      state: [''],
      city: [''],
      zipCode: [''],
      creditLimit: [''],
      remark: [''],
      countryCodeForMobileNo: ['']
    })
    
    this.updateDomainForm.get('countryCodeForMobileNo').valueChanges.subscribe(mobileCode => {
      let mobileData = this.mobileMinMaxLength.filter(data => Object.keys(data) == mobileCode)
      this.mobileMaxLength = mobileData[0][Object.keys(mobileData[0])[0]].max || '8';
      this.mobileMinLength = mobileData[0][Object.keys(mobileData[0])[0]].min || '10';
    })

    this.commonHelper.getCountryList(this.domainRequest).subscribe(res => {
      if (res.statusCode == 0) {
        this.countryList = res.data;
      } else {
        this.countryList = [];
      }
    })

    const sendToken = {
      token: localStorage.getItem('authToken'),
      domainId: localStorage.getItem('accessSelfDomainOnly') == 'YES' ? localStorage.getItem('domainId') : 'ALL'
    };
    this.commonHelper.makeRequest(sendToken, 'getDomainList', false).subscribe(res => { 
      if (res.statusCode == 0) {
        this.domainNameList=res.data;
        for(let i=0; i<this.domainNameList.length; i++){
          if(this.domainNameList[i].domainId == this.domainId){
            
            this.countryCodes = this.domainNameList[i].COUNTRY_CODES.split(",");
            this.zipCodeRegex = this.domainNameList[i].ZIPCODE_REGEX;
          }
        }
        var that = this;
        that.countryCodes.forEach(function (cc) {
        if (that.domainData && that.domainData.userDetails && that.domainData.userDetails.mobileCode && that.domainData.userDetails.mobileCode == cc) {
          that.updateDomainForm.patchValue({ countryCodeForMobileNo: cc });
        }
      });
    }
  })

    this.updateDomainForm.get('country').valueChanges.subscribe(country => {
      if (country) {
        const data = {
          token: localStorage.getItem('authToken'),
          countryCode: country
        }
        this.commonHelper.getStateList(data).subscribe(res => {
          if (res.statusCode == 0) {
            this.stateList = res.data;
            this.updateDomainForm.patchValue({ state: '' });
            var that = this;
            this.stateList.forEach(function (s) {
              if (s.stateCode == that.domainData.userDetails.stateCode) {
                that.updateDomainForm.patchValue({ state: that.domainData.userDetails.stateCode })
              }
            })
          } else {
            this.stateList = [];
          }
        })
      } else {
        this.updateDomainForm.patchValue({ state: '' });
        this.stateList = [];
      }
    });

    this.updateDomainForm.get('state').valueChanges.subscribe(state => {
      if (state) {
        const data = {
          token: localStorage.getItem('authToken'),          
          stateCode: state
        }

        this.commonHelper.getCityList(data).subscribe(res => {
          if (res.statusCode == 0) {
            this.cityList = res.data;
            this.updateDomainForm.patchValue({ city: '' });
            var that = this;
            this.cityList.forEach(function (c) {
              if (c.cityCode == that.domainData.userDetails.cityCode) {
                that.updateDomainForm.patchValue({ city: that.domainData.userDetails.cityCode })
              }
            })
          } else {
            this.cityList = [];
          }
        })
      } else {
        this.updateDomainForm.patchValue({ city: '' });
        this.cityList = [];
      }

    })

    this.updateType.valueChanges.subscribe(selectedType => {
      if (selectedType == "DOMAIN") {
        this.selectData = this.selectDomainContent;
        this.selectedField = "";
        if (this.selectData.length == 1) {
          this.updateDomainForm.patchValue({ selectedField: this.selectData[0] })
        } else {
          this.updateDomainForm.patchValue({
            selectedField: ""
          })
        }
      }

      if (selectedType == "PERSONAL") {
        this.selectData = this.selectPersonalContent;
        this.selectedField = "";
        this.updateDomainForm.patchValue({
          selectedField: ""
        })
      }

    })

    this.updateDomainForm.get('selectedField').valueChanges.subscribe(selectedField => {
      this.selectedField = selectedField;
      if (!selectedField) {
        return;
      } else if (selectedField.keyValue == "address") {
        this.updateDomainForm.patchValue({
          addressOne: this.domainData.userDetails.addressOne,
          addressTwo: this.domainData.userDetails.addressTwo,
          country: this.domainData.userDetails.countryCode,
          zipCode: this.domainData.userDetails.zipCode,
          state: this.domainData.userDetails.stateCode,
          city: this.domainData.userDetails.cityCode,
        });
        this.applyValidators(["addressOne", "addressTwo", "country", "state", "city", "zipCode"]);
        this.removeValidators(["fieldValue"]);
      } else if (selectedField.keyValue == "name" || selectedField.keyValue == "domainId") {
        this.updateDomainForm.patchValue({
          fieldValue: this.inputdomainData['' + selectedField.keyValue]
        })
        this.applyValidators(["fieldValue"]);
        this.removeValidators(["addressOne", "addressTwo", "country", "state", "city", "zipCode"]);
      } else {
        if (selectedField.keyValue == "mobileNumber") {

          var countryCode;
          var that = this;
          that.countryCodes.forEach(function (cc) {
            if (that.domainData.userDetails && that.domainData && that.domainData.userDetails.mobileCode && that.domainData.userDetails.mobileCode == cc) {
                that.updateDomainForm.patchValue({countryCodeForMobileNo : cc });
            }
          })

          if ((this.domainData.userDetails['' + selectedField.keyValue])) {
            this.updateDomainForm.patchValue({
              fieldValue: (this.domainData.userDetails['' + selectedField.keyValue])
            })
          } else {
            this.updateDomainForm.patchValue({
              fieldValue: ""
            })
          }
        } else {
          this.updateDomainForm.patchValue({
            fieldValue: this.domainData.userDetails['' + selectedField.keyValue]
          })
        }
        this.applyValidators(["fieldValue"]);
        this.removeValidators(["addressOne", "addressTwo", "country", "state", "city", "zipCode"]);

      }

    })

  }

  applyValidators(arr: string[]): void {
    arr.forEach((elem) => {
      this.updateDomainForm.get(elem).setValidators([Validators.required]);
      this.updateDomainForm.get(elem).updateValueAndValidity();
    })
  }

  removeValidators(arr: string[]): void {
    arr.forEach((elem) => {
      this.updateDomainForm.get(elem).setValidators(null);
      this.updateDomainForm.get(elem).updateValueAndValidity();
    })
  }

  updateDomainData(updateData): void {
    for (var key in updateData) {
      this.domainData.userDetails[key] = updateData[key];
      this.domainData.orgDetails[key] = updateData[key];
    }
  }

  updateDomain(): void {

    if (this.updateDomainForm.valid) {
      let state = this.stateList.filter(state => state.stateCode == this.updateDomainForm.get('state').value);
      let city = this.cityList.filter(city => city.cityCode == this.updateDomainForm.get('city').value);
      let updateData: any;
      if (this.updateDomainForm.get('selectedField').value.keyValue == "address") {
        updateData = {
          token: this.domainRequest.token,
          domainId: this.domainId,
          domainName: this.domainName,
          addressOne: this.updateDomainForm.get('addressOne').value,
          addressTwo: this.updateDomainForm.get('addressTwo').value,
          countryCode: this.updateDomainForm.get('country').value,
          state: state[0].stateName,
          city: city[0].cityName,
          stateCode: this.updateDomainForm.get('state').value,
          cityCode: this.updateDomainForm.get('city').value,
          zipCode: this.updateDomainForm.get('zipCode').value,
        }
      } else {
        updateData = {
          token: this.domainRequest.token,
          domainId: this.domainId,
          domainName: this.updateDomainForm.get('selectedField').value.keyValue == "name"?this.updateDomainForm.get('fieldValue').value:this.domainName,
          [this.updateDomainForm.get('selectedField').value.keyValue]: (this.updateDomainForm.get('selectedField').value.keyValue == "mobileNumber" ?  this.updateDomainForm.get('fieldValue').value : this.updateDomainForm.get('fieldValue').value),
          mobileCode: this.updateDomainForm.get('countryCodeForMobileNo').value
        }
      }
      this.commonHelper.makeRequest(updateData, 'updateDomain', true).subscribe(res => {
        if (res.statusCode == 0) {
          this.updateDomainData(updateData);
          this.responseMessage = res.message;
        } else {
          this.errorMessage = res.message;
        }
        this.commonHelper.animateMessage(this, "containerWrap");
      })

    }
  }

  closeModal() {
    this.activeModal.close(this.domainData);
  }

}
