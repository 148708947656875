declare var $: any;
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {CommonHelperService} from '../../../services/common-helper.service';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CustomValidators} from '../../directives/custom-validator';
import {environment} from 'src/environments/environment';
import {Md5} from 'ts-md5/dist/md5';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  responseMessage;
  setUserNameClass;
  setPasswordClass;
  selectedLang = localStorage.getItem('lang') || 'en';
  showLangButtons = environment.SHOWLANGBUTTON;
  loginHeaderLogo;
  loginFooterLogo = "";
  showButton: Boolean = false;
  showEye: Boolean = false;
  passwordRegexMessage;
  messages;
  allowdedLanguages = '';
  passwordRegex = environment.PASSWORD_REGEX;
  siteKey =environment.SITE_KEY;
  enableCaptcha=environment.ENABLE_CAPTCHA;
  constructor(private formBuilder: FormBuilder,
    private commonHelper: CommonHelperService,
    private router: Router,
    private translate: TranslateService,
    ) {
    if (localStorage.getItem('authToken') && localStorage.getItem('forcePasswordUpdate') == 'NO') {
      this.router.navigate(['RMS/dashboard']);
    } else if (localStorage.getItem('authToken'))  {
      this.router.navigate(['/change_password']);
    }
    this.commonHelper.setLogosConfiguration()
    this.commonHelper.loginHeaderLogo.subscribe(res => {
      this.loginHeaderLogo = res
    })
    this.commonHelper.loginFooterLogo.subscribe(res => {
      this.loginFooterLogo = res;
    })
    this.commonHelper.getSYSTEM_ALLOWED_LANGUAGES.subscribe(res => {
      this.allowdedLanguages = res;
      let langList = res ? res.split(',') : [];
      this.showLangButtons = false;
      if (langList[0]) {
        this.showLangButtons = true;
      }
    })
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userName: ['', CustomValidators.required],
      password: ['', CustomValidators.required],
      recaptcha: ['', Validators.required]
    });
    if(!this.enableCaptcha){
      this.loginForm.controls['recaptcha'].clearValidators();
      this.loginForm.controls['recaptcha'].updateValueAndValidity();
    }
    this.loginForm.valueChanges.subscribe(res => {
      this.responseMessage = ""
    })
    setTimeout(() => {
      [this.passwordRegexMessage, this.messages] = this.commonHelper.checkRegex(environment.PASSWORD_REGEX);
    }, 100)

  }

  authenticate() {
    let lang = localStorage.getItem('lang')
    this.translate.getTranslation(lang).subscribe(res=>{  
      localStorage.setItem('langSource',JSON.stringify(res.drodownValue));
    })
    if (this.loginForm.valid) {
      const md5 = new Md5();
      const requestData = {
        username: this.loginForm.controls.userName.value,
        password: this.loginForm.controls.password.value,
        // password: md5.appendStr(this.loginForm.controls.password.value).end(),
      };
      this.commonHelper.getToken(requestData).subscribe(res => {
        if (res.statusCode == 0) {
          // res.forcePasswordUpdate='NO';
          localStorage.setItem('authToken', res.authToken);
          localStorage.setItem('expiryTime', res.expiryTime);
          localStorage.setItem('userId', res.userId);
          localStorage.setItem('forcePasswordUpdate', res.forcePasswordUpdate);
          if (res.forcePasswordUpdate=='YES') {
            this.router.navigateByUrl('change_password').then(res=>{
              console.log("hello ");
            }).catch(err=>console.log("print logs",err));
          } 
          else {
            this.router.navigate(['RMS/dashboard']);
          }
        } else {
          this.responseMessage = res.message;
        }
      }, error => {
        this.responseMessage = 'No Internet Connection'
      });
    } else {
      this.validateAllFormFields(this.loginForm)
      this.responseMessage = ''
    }
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);
      if (control instanceof FormControl) {             //{4}
        control.markAsDirty({onlySelf: true});
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
  setLang(lang) {
    this.selectedLang = lang
    localStorage.setItem('lang', lang);
    this.commonHelper.prepareCustomMessages().subscribe(data => {
      this.commonHelper.customMessages = data;
    });
    $(window).trigger("changeLang", []);
  }

  setClass(field) {
    if (field == 'userName') {
      this.setUserNameClass = true;
    }
    if (field == 'password') {
      this.setPasswordClass = true;
    }
  }
  removeClass(field) {
    if (field == 'userName' && !this.loginForm.get('userName').value) {
      this.setUserNameClass = false;
    }
    if (field == 'password' && !this.loginForm.get('password').value) {
      this.setPasswordClass = false;
    }
  }

  showPassword() {
    this.showButton = !this.showButton;
    this.showEye = !this.showEye;
  }

}
