import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonHelperService } from 'src/app/services/common-helper.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-search-organization-modal',
  templateUrl: './update-search-organization-modal.component.html',
  styleUrls: ['./update-search-organization-modal.component.scss']
})
export class UpdateSearchOrganizationModalComponent implements OnInit {
  updateOrganizationForm: FormGroup;
  selectData;
  selectOtherContent;
  selectFinanceContent;
  countryList;
  stateList;
  cityList;
  regionList;
  orgId;
  errorMessage;
  responseMessage;
  selectedField: any;
  zipCodeRegex;
  emailRegex:any;
  domainRequest = {
    token: localStorage.getItem('authToken')
  };
  orgData;
  billConfigList = [];
  billConfigDescription = "";
  billTypes = [];
  domainData;
  selectTypeArray = [];
  countryCodes = [];
  mobileMaxLength;
  mobileMinLength;
  mobileMinMaxLength;
  showBillKeys = false;
  saleRepresentativeList = [];
  commissionGroupList = [];
  constructor(private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private cdr:ChangeDetectorRef,
    private commonHelper: CommonHelperService,
    private organizationService: OrganizationService
  ) {
    this.commonHelper.getMOBILE_CODE_MIN_MAX_LENGTH.subscribe(mobileCodeMinMax => {
      this.mobileMinMaxLength = mobileCodeMinMax;
    })
  }

  saleRepresentativeConfig = {
    limitTo: 50,
    displayKey:"userName",
    search:true, 
    height: 'auto', 
    placeholder:'Select',             
    customComparator: ()=>{},
    noResultsFound: 'No results found!',
    clearOnSelection: true,
    searchOnKey: 'userName'
};

  get updateType() {
    return this.updateOrganizationForm.get('updateType');
  }

  ngOnInit() {
    this.updateOrganizationForm = this.fb.group({
      updateType: ['', Validators.required],
      selectedField: ['', Validators.required],
      fieldValue: [''],
      addressOne: [''],
      addressTwo: [''],
      country: [''],
      state: [''],
      city: [''],
      region: [''],
      zipCode: [''],
      billType: [''],
      billConfigId: [''],
      bankName: [''],
      branchName: [''],
      beneficiaryName: [''],
      accountNumber: [''],
      remark: [''],
      countryCodeForMobileNo: [''],
      saleRepresentative: [''],
      commissionGroup: [''],
      creditLimit: ['']
    })
    this.emailRegex = this.domainData.EMAIL_REGEX;
    this.updateOrganizationForm.get('countryCodeForMobileNo').valueChanges.subscribe(mobileCode => {
      this.updateOrganizationForm.controls.fieldValue.markAsUntouched();
      let mobileData = this.mobileMinMaxLength.filter(data => Object.keys(data) == mobileCode)
      this.mobileMaxLength = mobileData[0][Object.keys(mobileData[0])[0]].max || '8';
      this.mobileMinLength = mobileData[0][Object.keys(mobileData[0])[0]].min || '10';
    });

    this.commonHelper.getCountryList(this.domainRequest).subscribe(res => {
      if (res.statusCode == 0) {
        this.countryList = res.data;
      }
    });

    this.countryCodes = this.domainData.COUNTRY_CODES.split(",");

    // var that = this;
    // this.countryCodes.forEach(function (cc) {
    //   if (that.orgData && that.orgData.mobileCode && that.orgData.mobileCode == cc) {
    //     that.updateOrganizationForm.patchValue({ countryCodeForMobileNo: cc });
    //   }
    // })

    this.commonHelper.getBillTypes(this.domainRequest).subscribe(res => {
      if (res.statusCode == 0) {
        this.billTypes = res.data;
      }
    });
    let initial = true;
    this.updateOrganizationForm.get('billType').valueChanges.subscribe(bType => {

      if (!bType) {
        return;
      }

      var reqObj = {
        token: this.domainRequest.token,
        billTypeId: bType
      }

      this.commonHelper.getBillConfig(reqObj).subscribe(res => {
        this.billConfigList = [];
        if (res.statusCode == 0) {
          this.billConfigList = res.data;
          if (initial) {
            this.updateOrganizationForm.patchValue({ billConfigId: this.orgData.billConfigid, emitEvent: false, onlySelf: true })
            initial = false;
          } else {
            this.updateOrganizationForm.patchValue({ billConfigId: '' });
          }
        } else {
          this.updateOrganizationForm.patchValue({ billConfigId: '' });
        }
      });

    });

    this.updateOrganizationForm.get('country').valueChanges.subscribe(country => {
      if (country) {
        const data = {
          token: localStorage.getItem('authToken'),
          countryCode: country
        }
        this.commonHelper.getStateList(data).subscribe(res => {
          if (res.statusCode == 0) {
            this.stateList = res.data;
            this.updateOrganizationForm.patchValue({ state: '' });
            var that = this;
            this.stateList.forEach(function (s) {
              if (s.stateCode == that.orgData.stateCode) {
                that.updateOrganizationForm.patchValue({ state: that.orgData.stateCode })
              }
            })
          }
        })
      } else {
        this.updateOrganizationForm.patchValue({ state: '' });
        this.stateList = [];
      }
    });

    this.updateOrganizationForm.get('state').valueChanges.subscribe(state => {
      if (state) {
        const data = {
          token: localStorage.getItem('authToken'),
          stateCode: state
        }

        this.commonHelper.getCityList(data).subscribe(res => {
          if (res.statusCode == 0) {
            this.cityList = res.data;
            this.updateOrganizationForm.patchValue({ city: '' });
            var that = this;
            this.cityList.forEach(function (c) {
              if (c.cityCode == that.orgData.cityCode) {
                that.updateOrganizationForm.patchValue({ city: that.orgData.cityCode })
              }
            })
          }
        })
      } else {
        this.updateOrganizationForm.patchValue({ city: '' });
        this.cityList = [];
      }
    });

    this.commonHelper.makeRequest({ token: localStorage.getItem('authToken') }, 'getRegionList', false).subscribe(res => {
      this.regionList = [];
      this.updateOrganizationForm.patchValue({ region: '' });
      if (res.statusCode == 0) {
        this.regionList = res.data;
        const that = this;
        this.regionList.forEach(function (c) {
          if (c.regionCode === that.orgData.regionCode) {
            that.updateOrganizationForm.patchValue({ region: that.orgData.regionCode });
          }
        });
      }

    });

      // this.commonHelper.makeRequest({ token: localStorage.getItem('authToken') }, 'getRegionList', false).subscribe(res => {
      //   this.regionList = [];
      //   this.updateOrganizationForm.patchValue({ region: '' });
      //   if (res.statusCode == 0) {
      //     this.regionList = res.data;
      //     const that = this;
      //     this.regionList.forEach(function (c) {
      //       if (c.regionCode === that.orgData.regionCode) {
      //         that.updateOrganizationForm.patchValue({ region: that.orgData.regionCode });
      //       }
      //     });
      //   }  else {
      //     this.updateOrganizationForm.patchValue({ region: '' });
      //     this.regionList = [];
      //   }
      // });

      // this.commonHelper.makeRequest({ token: localStorage.getItem('authToken'), domainId:  this.domainData.domainId}, 'getSaleRepresentative', false).subscribe(res => {
      //   this.saleRepresentativeList = [];
      //   this.updateOrganizationForm.patchValue({ saleRepresentative: '' });
      //   if (res.statusCode == 0) {
      //     this.saleRepresentativeList = res.data;
      //     this.saleRepresentativeList.forEach((c) => {
      //       if (c.id === this.orgData.salesReprId) {
      //         this.updateOrganizationForm.patchValue({ saleRepresentative: this.orgData.saleRep }); 
      //       }
      //     });
      //   } else {
      //     this.updateOrganizationForm.patchValue({ saleRepresentative: '' });
      //     this.saleRepresentativeList = [];
      //   }
      // });

    // });

    //  this.commonHelper.makeRequest({ token: localStorage.getItem('authToken') }, 'getRegionList', false).subscribe(res => {
    //         this.regionList = [];
    //         this.updateOrganizationForm.patchValue({ region: '' });
    //         if (res.statusCode == 0) {
    //           this.regionList = res.data;
    //           const that = this;
    //           this.regionList.forEach(function (c) {
    //             if (c.regionCode === that.orgData.regionCode) {
    //               that.updateOrganizationForm.patchValue({ region: that.orgData.regionCode });
    //             }
    //           });
    //         }  else {
    //           this.updateOrganizationForm.patchValue({ region: '' });
    //           this.regionList = [];
    //         }
    //       });

    // this.updateOrganizationForm.get('region').valueChanges.subscribe(region => {
    //   if (region) {
    //     const data = {
    //       token: localStorage.getItem('authToken')
    //     };
    
    //     this.commonHelper.getRegionList(data).subscribe(res => {
    //       if (res.statusCode == 0) {
    //         this.regionList = res.data;
    //         this.updateOrganizationForm.patchValue({ region: '' });
    //         this.regionList.forEach(c => {
    //           if (c.regionCode === this.orgData.regionCode) {
    //             this.updateOrganizationForm.patchValue({ region: this.orgData.regionCode });
    //           }
    //         });
    //       } else {
    //         this.updateOrganizationForm.patchValue({ region: '' });
    //         this.regionList = [];
    //       }
    //     });
    //   } else {
    //     this.updateOrganizationForm.patchValue({ region: '' });
    //     this.regionList = [];
    //   }
    // });


    
    this.commonHelper.makeRequest({ token: localStorage.getItem('authToken'), domainId:  this.domainData.domainId}, 'getSaleRepresentative', false).subscribe(res => {
      this.saleRepresentativeList = [];
      this.updateOrganizationForm.patchValue({ saleRepresentative: '' });
      if (res.statusCode == 0) {
        this.saleRepresentativeList = res.data;
        // const that = this;
        // this.saleRepresentativeList.forEach(function (c) {
        //   if (c.id === that.orgData.id) {
        //     that.updateOrganizationForm.patchValue({ saleRepresentative: c.id });
        //   }
        // });
      }
    });

    this.commonHelper.makeRequest({ token: localStorage.getItem('authToken'), domainId: this.domainData.domainId, isView: true, orgId: this.orgId }, 'getCommGroups', true).subscribe(res => {
      this.commissionGroupList = [];
      this.updateOrganizationForm.patchValue({ commissionGroup: '' });
      if (res.statusCode == 0) {
        this.commissionGroupList = res.data.groupData;
        this.saleRepresentativeList.forEach((c) => {
          if (c.id === this.orgData.salesReprId) {
            this.updateOrganizationForm.patchValue({ saleRepresentative: this.orgData.saleRep }); 
          }
        });
      } else {
        this.updateOrganizationForm.patchValue({ saleRepresentative: '' });
        this.saleRepresentativeList = [];
      }
    });

    this.updateType.valueChanges.subscribe(selectedType => {
      if (selectedType == "FINANCE") {
        this.selectData = this.selectFinanceContent;
        this.selectedField = "";
        if (this.selectData.length == 1) {
          this.updateOrganizationForm.patchValue({ selectedField: this.selectData[0] });
        } else {
          this.updateOrganizationForm.patchValue({
            selectedField: "",
          })
        }
      }

      if (selectedType == "OTHER") {
        this.selectData = this.selectOtherContent;
        this.selectedField = "";
        this.updateOrganizationForm.patchValue({
          selectedField: ""
        })
      }

      if (selectedType == "BUSINESS"){
        this.selectData = [{"keyValue": "orgName", "keyName": this.commonHelper.getCustomMessages("orGName")},
                          {"keyValue": "orgCode", "keyName": this.commonHelper.getCustomMessages("orgCode")},
                          // {"keyValue": "saleRepresentative", "keyName": this.commonHelper.getCustomMessages("saleRepresentative")},
                          {"keyValue": "salesReprId", "keyName": this.commonHelper.getCustomMessages("saleRepresentative")},
                          {"keyValue": "commissionGroup", "keyName": this.commonHelper.getCustomMessages("commissionGroup")}];
                          // {"keyValue": "salesReprId", "keyName": this.commonHelper.getCustomMessages("saleRepresentative")}];
        this.selectedField = "";
        this.updateOrganizationForm.patchValue({
          selectedField: ""
        })
      }

    })

    this.updateOrganizationForm.get('selectedField').valueChanges.subscribe(selectedField => {
      this.selectedField = selectedField;
      //this.removeValidators(["fieldValue", "addressOne", "addressTwo", "country", "state", "city", "zipCode", "creditLimit", "remark", "billType", "billConfigId", "region", "saleRepresentative", "commissionGroup, bankName", "branchName","beneficiaryName", "accountNumber"]);
      if (!selectedField) {
        return;
      } else if (selectedField.keyValue == "address") {
        this.zipCodeRegex = this.domainData.ZIPCODE_REGEX;
        this.updateOrganizationForm.patchValue({
          addressOne: this.orgData.addressOne,
          addressTwo: this.orgData.addressTwo,
          country: this.orgData.countryCode,
          region: this.orgData.regionCode,                    //  It is only done for UNL client..
          zipCode: this.orgData.zipCode
        });

        this.applyValidators(["addressOne", "addressTwo", "country", "state", "city", "zipCode", "billType", "billConfigId", "creditLimit", "remark", "region"]);
        this.removeValidators(["fieldValue", "remark", "billType", "billConfigId", "creditLimit", "saleRepresentative", "commissionGroup"]);

      } else if (selectedField.keyValue == "billConfigid") {
        if (
          (this.orgData.walletType == "PREPAID")
        ) {
          this.showBillKeys = false;
          this.applyValidators(["creditLimit", "remark"]);
        } else {
          this.showBillKeys = true;
          this.applyValidators(["billType", "billConfigId", "creditLimit", "remark"]);
        }
        this.setMinMax('creditLimit', parseFloat(this.domainData.DEFAULT_CREDIT_LIMIT), parseFloat(this.domainData.MAX_CREDIT_LIMIT))
        this.removeValidators(["fieldValue", "addressOne", "addressTwo", "country", "state", "city", "zipCode", "region", "saleRepresentative", "commissionGroup"]);
        this.updateOrganizationForm.patchValue({ billType: this.orgData.billTypeId, emitEvent: false, onlySelf: true })

        var decimalCharacter = localStorage.getItem("decimalCharacter"),
          allowedDecimalDigits = parseInt(localStorage.getItem("allowedDecimalDigits")),
          thousandSeparator = localStorage.getItem("thousandSeparator");
        var creditLimit = this.orgData.rawCreditLimit + "";
        if (!allowedDecimalDigits) {
          creditLimit = creditLimit.split(decimalCharacter).join("");
          creditLimit = creditLimit.split(thousandSeparator).join("");
        } else {
          creditLimit = creditLimit.split(thousandSeparator).join("");
          var indexOfDeci = creditLimit.indexOf(decimalCharacter);
          if (indexOfDeci > -1) {
            creditLimit = creditLimit.substring(0, (indexOfDeci + 1) + allowedDecimalDigits);       
          }
        }
        this.updateOrganizationForm.patchValue({ creditLimit: creditLimit, onlySelf: true });
      } else if (selectedField.keyValue == "salesReprId") {
        this.applyValidators(["saleRepresentative"]);                 
        const that = this;
        this.saleRepresentativeList.forEach(function (c) {
          if (c.id === that.orgData.id) {
            that.updateOrganizationForm.patchValue({ saleRepresentative: c.id });
          }
        });
      } else if (selectedField.keyValue == "commissionGroup") {
        this.applyValidators(["commissionGroup"]);
        const that = this;
        this.commissionGroupList.forEach(function (c) {
          if (c.name === that.orgData.commGroupName) {
            that.updateOrganizationForm.patchValue({ commissionGroup: c.id });
          }
        });
        // this.updateOrganizationForm.patchValue({ commissionGroup: this.orgData.commGroupName });
      } else if (selectedField.keyValue == "bankDetails") {
        this.applyValidators(["bankName", "branchName","beneficiaryName", "accountNumber"]);
        this.removeValidators(["fieldValue", "addressOne", "addressTwo", "country", "state", "city", "zipCode", "creditLimit", "remark", "billType", "billConfigId", "region", "saleRepresentative", "commissionGroup"]);
        this.updateOrganizationForm.patchValue({ bankName: this.orgData.bankName });
        this.updateOrganizationForm.patchValue({ branchName: this.orgData.branchName });
        this.updateOrganizationForm.patchValue({ beneficiaryName: this.orgData.beneficiaryName });
        this.updateOrganizationForm.patchValue({ accountNumber: this.orgData.accountNumber });
      } else {

        if (selectedField.keyValue == "mobileNumber") {
          var countryCode;
          var that = this;
          this.countryCodes.forEach(function (cc) {
            if (that.orgData && that.orgData.mobileCode && that.orgData.mobileCode == cc) {
              that.updateOrganizationForm.patchValue({ countryCodeForMobileNo : cc});
            }
          })
          if ((this.orgData['' + selectedField.keyValue])) {
            this.updateOrganizationForm.patchValue({
              fieldValue: (this.orgData['' + selectedField.keyValue])
            })
          } else {
            this.updateOrganizationForm.patchValue({
              fieldValue: ""
            })
          }
        } else {
          this.updateOrganizationForm.patchValue({
            fieldValue: this.orgData['' + selectedField.keyValue]
          });
        }
        this.applyValidators(["fieldValue"]);                 
        this.removeValidators(["addressOne", "addressTwo", "country", "state", "city", "zipCode", "billType", "billConfigId", "creditLimit", "remark", "region", "saleRepresentative", "commissionGroup"]);
      }
    })
  }

  applyValidators(arr: string[]): void {
    arr.forEach((elem) => {
      this.updateOrganizationForm.get(elem).setValidators([Validators.required]);
      this.updateOrganizationForm.get(elem).updateValueAndValidity();
    })
  }

  removeValidators(arr: string[]): void {
    arr.forEach((elem) => {
      if(elem) {
        this.updateOrganizationForm.get(elem).setValidators(null);
        this.updateOrganizationForm.get(elem).updateValueAndValidity();
      }
      
    })
  }

  updateOrgData(updateData): void {
    for (var key in updateData) {
      this.orgData[key] = updateData[key];         
      if(key == "regionCode") {
        this.regionList.forEach(c => {
          if (c.regionCode === updateData[key]) {
            this.orgData['regionName'] = c.regionName;
          }
        });
      } else if(key == 'salesReprId'){
        this.saleRepresentativeList.forEach((c) => {
          if (c.id === updateData[key]) {
            this.orgData['saleRep'] = c.userName;
          //  console.log(updateData[key],'lolololo',this.orgData);
          }
        });
      } else if(key == 'commGroupId') {
        this.commissionGroupList.forEach((c) => {
          if (c.id == updateData[key]) {
           this.orgData['commGroupName'] = c.name;
          }
        });
      }
    }
  }

  updateOrganization(): void {

    if (this.updateOrganizationForm.valid) {
      let updateData: any;
      if (this.updateOrganizationForm.get('selectedField').value.keyValue == "commissionGroup") {
        updateData = {
          token: this.domainRequest.token,
          orgId: this.orgId,
          commGroupId: this.updateOrganizationForm.get('commissionGroup').value,       
          domainId:this.domainData.domainId
        }
        this.commonHelper.makeRequest(updateData, 'assignCommGroup', true).subscribe(res => {
          if (res.statusCode == 0) {
            this.responseMessage = res.message;
            this.updateOrgData(updateData);
          } else {
            this.errorMessage = res.message;
          }
          this.commonHelper.animateMessage(this, "containerWrap");
        })
        return;
      }
      if (this.updateOrganizationForm.get('selectedField').value.keyValue == "billConfigid") {
        var creditLimit;
        creditLimit = this.updateOrganizationForm.get('creditLimit').value + "";
        if (!parseInt(localStorage.getItem("allowedDecimalDigits"))) {
          creditLimit = creditLimit.split(localStorage.getItem("decimalCharacter")).join("");
          creditLimit = creditLimit.split(localStorage.getItem("thousandSeparator")).join("");
        } else {
          creditLimit = creditLimit.split(localStorage.getItem("thousandSeparator")).join("");
          var indexOfDeci = creditLimit.indexOf(localStorage.getItem("decimalCharacter"));
          if (indexOfDeci > -1) {
            creditLimit = creditLimit.substring(0, (indexOfDeci + 1) + parseInt(localStorage.getItem("allowedDecimalDigits")))
          }
        }

        var reg;
        if (localStorage.getItem("decimalCharacter") == ".") {
          reg = new RegExp("\\" + localStorage.getItem("decimalCharacter"), "g");
        } else {
          reg = new RegExp(localStorage.getItem("decimalCharacter"), "g");
        }

        creditLimit = creditLimit.replace("$", "");

        creditLimit = parseFloat((creditLimit).replace(reg, "."));

        updateData = {
          token: this.domainRequest.token,
          orgId: this.orgId,
          creditLimit: creditLimit,
          billConfigId: this.updateOrganizationForm.get('billConfigId').value,
          remark: this.updateOrganizationForm.get('remark').value,
          domainId:this.domainData.domainId
        }

        if (this.orgData.walletType == "PREPAID") {
          updateData.billConfigId = 0;
        }

        this.commonHelper.makeRequest(updateData, 'raiseCreditConfigChangeRequest', true).subscribe(res => {
          if (res.statusCode == 0) {
            this.responseMessage = res.message;
          } else {
            this.errorMessage = res.message;
          }
          this.commonHelper.animateMessage(this, "containerWrap");
        })
        return;
      }

      if (this.updateOrganizationForm.get('selectedField').value.keyValue == "address") {
        updateData = {
          token: this.domainRequest.token,
          orgId: this.orgId,
          addressOne: this.updateOrganizationForm.get('addressOne').value,
          addressTwo: this.updateOrganizationForm.get('addressTwo').value,
          countryCode: this.updateOrganizationForm.get('country').value,
          stateCode: this.updateOrganizationForm.get('state').value,
          cityCode: this.updateOrganizationForm.get('city').value,
          zipCode: this.updateOrganizationForm.get('zipCode').value,
          regionCode: this.updateOrganizationForm.get('region').value,
        }
      } else if(this.updateOrganizationForm.get('selectedField').value.keyValue == "bankDetails") {
        updateData = {
          token: this.domainRequest.token,
          orgId: this.orgId,
          bankName: this.updateOrganizationForm.get('bankName').value,
          branchName: this.updateOrganizationForm.get('branchName').value,
          accountNumber: this.updateOrganizationForm.get('accountNumber').value,
          beneficiaryName: this.updateOrganizationForm.get('beneficiaryName').value,
        }
      } else {
        // let keyValue = this.updateOrganizationForm.get('selectedField').value.keyValue;
        // let domain = this.domainData.domainId;
        updateData = {
          token: this.domainRequest.token,
          domainId : this.domainData.domainId,
          orgId: this.orgId,
          [this.updateOrganizationForm.get('selectedField').value.keyValue]: (this.updateOrganizationForm.get('selectedField').value.keyValue == "mobileNumber" ?  this.updateOrganizationForm.get('fieldValue').value : this.updateOrganizationForm.get('fieldValue').value),
          mobileCode: this.updateOrganizationForm.get('countryCodeForMobileNo').value,
          salesReprId:this.updateOrganizationForm.get('saleRepresentative').value.id
        };
        // if (keyValue == "salesReprId") {
        //   updateData.domainId = domain;
        //   updateData.salesReprId = this.updateOrganizationForm.get('saleRepresentative').value.id;
        // }
        // console.log(updateData,'updatedd')
      }
      this.commonHelper.makeRequest(updateData, 'updateOrgDetails', true).subscribe(res => {
        if (res.statusCode == 0) {
          this.updateOrgData(updateData);
          this.responseMessage = res.message;
        } else {
          this.errorMessage = res.message;
        }
        this.commonHelper.animateModalMessage(this, "containerWrap");
      })

    }
  }

  closeModal() {
    this.activeModal.close(this.orgData);
  }

  setMinMax(fieldName, min, max) {
    // console.log(fieldName,min,max)
    this.updateOrganizationForm.controls[fieldName].setValidators([Validators.required, Validators.min(min), Validators.max(max)]);
    this.updateOrganizationForm.controls[fieldName].updateValueAndValidity()
  }


  setMinMaxZero() {
    if (this.updateOrganizationForm.get('creditLimit').value == 0)
      this.updateOrganizationForm.patchValue({ creditLimit: 0 })
  }
}
