import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'approvalPipe'
})
export class ApprovalFilterPipe implements PipeTransform {
  constructor() { }
  transform(array: any, query: string): any {


    if (query) {
      return array.filter(row => {
        if (!row.configDescription) {
          row.configDescription = "";
        }
        const requestId = row.requestId.toLowerCase().includes(query.toLowerCase())
        const winningCreatedAt = row.winningCreatedAt.toLowerCase().includes(query.toLowerCase())
        const ticketNumber = row.ticketNumber.toLowerCase().includes(query.toLowerCase())
        const firstName = row.firstName.toLowerCase().includes(query.toLowerCase())
        const middleName = row.middleName.toLowerCase().includes(query.toLowerCase())
        const lastName = row.lastName.toLowerCase().includes(query.toLowerCase())
        const requestedBy = row.requestedBy.toLowerCase().includes(query.toLowerCase())
        const claimAmount = row.claimAmount.toLowerCase().includes(query.toLowerCase())
        const tdsValue = row.tdsValue.toLowerCase().includes(query.toLowerCase())
        const netPayable = row.netPayable.toLowerCase().includes(query.toLowerCase())
        return (requestId + winningCreatedAt + ticketNumber + firstName + middleName + lastName + requestedBy + claimAmount + tdsValue + netPayable);
      }
      )
    }
    return array;

  }

}
