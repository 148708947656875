
import { CommonHelperService } from "../services/common-helper.service";
export class BulkPaymentReport{

    static readonly configDomain = {
        displayKey: "domainName",
        search: true,
        height: 'auto',
        placeholder: 'Select Domain',
        customComparator: () => { },
        noResultsFound: 'No results found!',
        clearOnSelection: true,
        searchOnKey: 'domainName'
        
    };
 constructor(private commonHelper: CommonHelperService,) { }
 
    satusList =[{key: "ALL", value: this.commonHelper.changeByLang("All")},
                {key: "DONE", value: this.commonHelper.changeByLang("Done")},
                {key: "IN_PROGRESS", value: this.commonHelper.changeByLang("In Progress")},
                {key: "PENDING", value:this.commonHelper.changeByLang("Pending")}]
}

export class commonDropdownConfig{
    langType = localStorage.getItem('lang');
    langSource = JSON.parse(localStorage.getItem('langSource'));
    //commision component 
    configOrgType = {
        limitTo: 50,
        displayKey:"orgName",
        search:true,
        height: 'auto',
        searchPlaceholder: this.langSource.search,
        placeholder:this.langSource.select,
        customComparator: ()=>{},
        noResultsFound: this.langSource.noResultsFound,
        clearOnSelection: true,
        searchOnKey: 'orgName'
    }; 
    configOrgTypePaymentReport = {
        limitTo: 50,
        displayKey:"orgName",
        search:true, 
        height: 'auto', 
        searchPlaceholder: this.langSource.search,
        placeholder:this.langSource.all,              
        customComparator: ()=>{},
        noResultsFound: this.langSource.noResultsFound,
        clearOnSelection: true,
        searchOnKey: 'orgName'
      };
    configDomain = {
        limitTo: 50,
        displayKey:"domainName",
        search:true, 
        height: 'auto', 
        searchPlaceholder: this.langSource.search,
        placeholder:this.langSource.select,              
        customComparator: ()=>{},
        noResultsFound: this.langSource.noResultsFound,
        clearOnSelection: true,
        searchOnKey: 'domainName'
    };

    saleRepresentative = {
      limitTo: 50,
      displayKey:"userName",
      search:true, 
      height: 'auto', 
      searchPlaceholder: this.langSource.search,
      placeholder:this.langSource.select,              
      customComparator: ()=>{},
      noResultsFound: this.langSource.noResultsFound,
      clearOnSelection: true,
      searchOnKey: 'userName'
  };
    // define set component
    dropdownSettings = {
        enableCheckAll: false,
        text: this.langSource.select, 
        primaryKey: 'txnTypeCode',
        labelKey: 'txnTypeName',
        selectAllText: this.langSource.selectAll,
        unSelectAllText: this.langSource.unSelectAll,
        itemsShowLimit: 10,
        allowSearchFilter: false,
        classes: "myclass custom-class",
        // lazyLoading: true,
        groupBy: 'category',
        selectGroup: true,
      };
    
      dropdownServiceSettings = {
        text: this.langSource.select, 
        primaryKey: 'serviceCode',
        labelKey: 'serviceDisplayName',
        selectAllText: this.langSource.selectAll,
        unSelectAllText: this.langSource.unSelectAll,
        itemsShowLimit: 10,
        allowSearchFilter: false,
        classes: "myclass custom-class",
        // lazyLoading: true,
      };
    
      dropdownGamesSettings = {
        text: this.langSource.select, 
        primaryKey: 'gameId',
        labelKey: 'gameName',
        selectAllText: this.langSource.selectAll,
        unSelectAllText: this.langSource.unSelectAll,
        itemsShowLimit: 10,
        allowSearchFilter: false,
        classes: "myclass custom-class",
        // lazyLoading: true,
      };
      configStatus = {
        displayKey: "value",
        search: true,
        height: 'auto',
        searchPlaceholder: this.langSource.search,
        placeholder:this.langSource.select,    
        noResultsFound: this.langSource.noResultsFound,
        clearOnSelection: true,
        searchOnKey: 'key'
    };

    servingRegionconfig = {
      displayKey: "regionName",
      search: false,
      height: 'auto',
      placeholder: this.langSource.select,
      customComparator: () => { },
      limitTo: 20,
      noResultsFound: this.langSource.noResultsFound,
    };

    wareHouseRegionconfig = {
      displayKey: "regionName",
      search: false,
      height: 'auto',
      placeholder: this.langSource.selectRegion,
      customComparator: () => { },
      limitTo: 20,
      noResultsFound: this.langSource.noResultsFound,
    };

    dropdownSaleRepresentative = {
      singleSelection: true,
      idField: 'id',
      textField: 'userName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
}