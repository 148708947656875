import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './pipes/safe.pipe';
import { BaCardComponent } from './components/ba-card/ba-card.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { CharacterOnlyDirective } from './directives/character-only.directive';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AppTranslationModule } from '../app-translation.module';
import { UpdateSearchOrganizationModalComponent } from './components/modal/update-search-organization-modal/update-search-organization-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SearchFilterPipePipe } from './pipes/search-filter-pipe.pipe';
import { TitlePageComponent } from './components/title-page/title-page.component';
import { RouterModule } from '@angular/router';
import { PasswordComponent } from './components/password/password.component';
import { NoSpaceDirective } from './directives/no-space.directive';
import { CommissionValuesDirective } from './directives/commission-values.directive';
import { InitDirectiveDirective } from './directives/init-directive.directive';
import { UsernameDirective } from './directives/username.directive';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { DetailInventoryModalComponent } from './components/modal/detail-inventory-modal/detail-inventory-modal.component';
import { DataTableModule } from 'angular-6-datatable';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { BlockUIModule } from 'ng-block-ui';
import { TrimOnBlurDirective } from './directives/trim-on-blur.directive';
import { CurrencyValuesDirective } from './directives/currency-values.directive';
import { CurrencyPipePipe } from './pipes/currency-pipe.pipe';
import { CommissionPipePipe } from './pipes/commission-pipe.pipe';
import { ApprovalFilterPipe } from './pipes/approval-pipe';
import { ManageWinningPipePipe } from './pipes/manage-winning-pipe.pipe';
import { PlayerWinningPipePipe } from './pipes/player-winning-pipe.pipe';
import { SearchOrgPipePipe } from './pipes/search-org-pipe.pipe';
import { SearchDomainPipePipe } from './pipes/search-domain-pipe.pipe'
import { SearchPosPipePipe } from './pipes/search-pos-pipe.pipe';
import { SearchVersionPipePipe } from './pipes/search-version-pipe.pipe';
import { SearchUserPipePipe } from './pipes/search-user-pipe.pipe';
import { PermissionPipePipe } from './pipes/permission-pipe';
import { UppercaseDirective } from './directives/uppercase.directive';
import { HandleMutliClickDirective } from './directives/handle-mutli-click.directive';
import { OrgChainReusableComponent } from './components/org-chain-reusable/org-chain-reusable.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { OrgPosAssignComponent } from '../rms/configurations/org-pos-assign/org-pos-assign.component';
import { TextboxWithNineValuesDirective } from './directives/textbox-with-nine-values.directive';
import { AccordianComponent } from './components/accordian/accordian.component';
import { CountryStateCityFormComponent } from './components/country-state-city-form/country-state-city-form.component';
import { UpdateSearchDomainModalComponent } from './components/modal/update-search-domain/update-search-domain-modal.component';
import { UserMenuPrivilegesComponent } from './components/user-menu-privileges/user-menu-privileges.component';
import { TimeDirectiveDirective } from './directives/time-directive.directive';
import { ConfirmPayoutModalComponent } from './components/modal/confirm-payout-modal/confirm-payout-modal.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import { DomainDropdownComponent } from './components/domain-dropdown/domain-dropdown.component';
@NgModule({
  declarations: [DomainDropdownComponent, UnauthorizedComponent, SafePipe, BaCardComponent, NumberOnlyDirective, CharacterOnlyDirective, UpdateSearchOrganizationModalComponent, SearchFilterPipePipe, TitlePageComponent, PasswordComponent, NoSpaceDirective, CommissionValuesDirective, ForgotPasswordComponent, InitDirectiveDirective, UsernameDirective, DetailInventoryModalComponent, UnauthorizedComponent, TrimOnBlurDirective, CurrencyValuesDirective, CurrencyPipePipe, CommissionPipePipe, ApprovalFilterPipe, ManageWinningPipePipe, PlayerWinningPipePipe, SearchDomainPipePipe, SearchOrgPipePipe, SearchUserPipePipe, HandleMutliClickDirective, PermissionPipePipe, UppercaseDirective, OrgChainReusableComponent, SearchPosPipePipe, SearchVersionPipePipe, OrgPosAssignComponent, TextboxWithNineValuesDirective, AccordianComponent, CountryStateCityFormComponent, UpdateSearchDomainModalComponent, UserMenuPrivilegesComponent, TimeDirectiveDirective, ConfirmPayoutModalComponent],
  imports: [
    CommonModule,
    NgbModule,
    AppTranslationModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    DataTableModule,
    SelectDropDownModule,
    BlockUIModule.forRoot(),
    AngularMultiSelectModule,
    NgxCaptchaModule
  ],

  exports: [DomainDropdownComponent, CurrencyPipePipe, CommissionPipePipe, UnauthorizedComponent, SafePipe, CurrencyValuesDirective, BaCardComponent, NoSpaceDirective, NumberOnlyDirective, CharacterOnlyDirective, SearchFilterPipePipe, PasswordComponent, TitlePageComponent, InitDirectiveDirective, CommissionValuesDirective, UsernameDirective, SelectDropDownModule, BlockUIModule, TrimOnBlurDirective, ApprovalFilterPipe, ManageWinningPipePipe, PlayerWinningPipePipe, SearchDomainPipePipe, SearchOrgPipePipe, SearchUserPipePipe, HandleMutliClickDirective, PermissionPipePipe, UppercaseDirective, OrgChainReusableComponent, SearchPosPipePipe, SearchVersionPipePipe, OrgPosAssignComponent, TextboxWithNineValuesDirective, AccordianComponent, CountryStateCityFormComponent, UserMenuPrivilegesComponent, TimeDirectiveDirective],
  entryComponents: [UpdateSearchOrganizationModalComponent, DetailInventoryModalComponent, UpdateSearchDomainModalComponent, ConfirmPayoutModalComponent],
  providers: [CurrencyPipePipe, CommissionPipePipe, CommissionValuesDirective],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

})
export class SharedModule { }
