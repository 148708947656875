import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'playerWinningPipe'
})
export class PlayerWinningPipePipe implements PipeTransform {

  transform(array: any, query: string): any {
    if (query != undefined && query) {
      return array.filter(row => {
        if (!row.configDescription) {
          row.configDescription = ""
        }
        const requestId = row.requestId.toLowerCase().includes(query.toLowerCase())
        const requestTime = row.requestTime.toLowerCase().includes(query.toLowerCase())
        const ticketNumber = row.ticketNumber.toLowerCase().includes(query.toLowerCase())
        const winningAmount = row.winningAmount.toLowerCase().includes(query.toLowerCase())
        const playerTds = row.playerTds.toLowerCase().includes(query.toLowerCase())
        const netPayableAmount = row.netPayableAmount.toLowerCase().includes(query.toLowerCase())
        const playerName = row.playerName.toLowerCase().includes(query.toLowerCase())
        const requestedBy = row.requestedBy.toLowerCase().includes(query.toLowerCase())
        const status = row.status.toLowerCase().includes(query.toLowerCase())
        return (requestId + requestTime + ticketNumber + winningAmount + playerTds + netPayableAmount + playerName + requestedBy + status);
      }
      )
    }
    return array;
  }

}
