import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'searchFilterPipe'
})
export class SearchFilterPipePipe implements PipeTransform {
  constructor() { }
  transform(array: any, query: string): any {


    if (query != undefined && query) {
      return array.filter(row => {
        if (!row.configDescription) {
          row.configDescription = "";
        }
        const requestId = row.requestId.toLowerCase().includes(query.toLowerCase())
        const createdAt = row.createdAt.toLowerCase().includes(query.toLowerCase())
        const orgName = row.orgName.toLowerCase().includes(query.toLowerCase())
        const creditLimit = row.creditLimit.toLowerCase().includes(query.toLowerCase())
        const configDescription = row.configDescription.toLowerCase().includes(query.toLowerCase())
        const remark = row.remark.toLowerCase().includes(query.toLowerCase())
        const requestedUserName = row.requestedUserName.toLowerCase().includes(query.toLowerCase())
        return (requestId + createdAt + orgName + creditLimit + configDescription + remark + requestedUserName);
      }
      )
    }
    return array;

  }

}
