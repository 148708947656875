import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { CurrencyPipePipe } from '../pipes/currency-pipe.pipe';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCurrencyValues]'
})
export class CurrencyValuesDirective {
  private el: any;
  @Input('appCurrencyValues') defaultValue;
  constructor(
    private elementRef:ElementRef,
    private formatcurrencypipe:CurrencyPipePipe,
    private control : NgControl
  ) { 
    this.el = this.elementRef.nativeElement;
  }
  ngOnInit(){
    this.el.value = this.formatcurrencypipe.transform(this.el.value,this.defaultValue);
    this.control.control.setValue( this.el.value);
  }
  @HostListener("focus",["$event.target.value","$event"])
  onFocus(value,event) {
    this.el.value = this.formatcurrencypipe.transform(value,this.defaultValue);
    this.control.control.setValue(this.el.value);
    if(event.which== 9)
    {
        return false;
    }
    this.el.select();  
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    
    this.el.value = this.formatcurrencypipe.transform(value,this.defaultValue);
    this.control.control.setValue( this.el.value);
  }
  @HostListener('keypress', ['$event']) onKeyPress(event) {
    let e = <KeyboardEvent>event;
    const charCode = (e.which) ? e.which : e.keyCode;
    if ((charCode >= 48  && charCode <= 57) || e.key == localStorage.getItem("decimalCharacter") ||  e.key == localStorage.getItem("thousandSeparator")) {
      if (e.key == localStorage.getItem("decimalCharacter") && event.target.value.includes(localStorage.getItem("decimalCharacter"))){
        return false;
      }
      return true;
    }else{
      return false;
    }
  }

}