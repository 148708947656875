import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchVersionPipe'
})
export class SearchVersionPipePipe implements PipeTransform {
    transform(array: any, query: string): any {
        if (query != undefined && query) {
            return array.filter(row => {
                if (row.configDescription == null)
                    row.configDescription = ""
                const appType = row.orgType.toLowerCase().includes(query.toLowerCase())
                const version = row.orgName.toLowerCase().includes(query.toLowerCase())
                const fileSize = row.emailId.toLowerCase().includes(query.toLowerCase())
                const downloadSize = row.mobileNumber.toLowerCase().includes(query.toLowerCase())
                const downloadStatus = row.mobileNumber.toLowerCase().includes(query.toLowerCase())
                const mandatory = row.mobileNumber.toLowerCase().includes(query.toLowerCase())
                return (appType + version + fileSize + downloadSize + downloadStatus + mandatory);
            }
            )
        }
        return array;
    }
}