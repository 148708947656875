import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appUppercase]'
})
export class UppercaseDirective {
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  inputElement: HTMLElement;
  constructor(public el: ElementRef, public control: NgControl) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true)  // Allow: Cmd+X (Mac)  
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress   
    const charCode = (e.which) ? e.which : e.keyCode;
    //  console.log(e)
    if (!((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || (charCode >= 96 && charCode <= 105) || (!e.shiftKey && charCode >= 48 && charCode <= 57) || (e.key == "_"))) {
      e.preventDefault();
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event) {
    const textData = event.srcElement.value.trim();
    if (textData.length == 1 && event.key == "_") {
      this.control.control.setValue("");
    } else {
      var selectionStart = event.srcElement.selectionStart;
      this.control.control.setValue(textData.toUpperCase());
      event.srcElement.selectionEnd = selectionStart;
    }
  }
  @HostListener('blur', ['$event'])
  onBlur(event) {
    const textData = event.srcElement.value.trim();
    if (textData.length == 1 && event.key == "_") {
      this.control.control.setValue("");
    } else {
      this.control.control.setValue(textData.toUpperCase());
    }
  }
}



