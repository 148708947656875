import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[TextboxWithNineValues]'
})
export class TextboxWithNineValuesDirective {

  constructor(private el: ElementRef, public control: NgControl) { }
  @HostListener('keypress', ['$event']) onKeyPress(event) {
    let e = <KeyboardEvent>event;
    const charCode = (e.which) ? e.which : e.keyCode;
    if ((event.srcElement.value.charAt(event.srcElement.value.length - 1) == ',' && charCode == 44)|| (charCode==44 && event.srcElement.value.length==0)) {
      return false
    }
    const elements = event.srcElement.value.split(',')
    if (elements.length > 10) {
      return false
    }
  }

  @HostListener('blur', ['$event']) onblur(event) {
    if(event.srcElement.value.charAt(event.srcElement.value.length-1)==','){
      event.srcElement.value=   event.srcElement.value.slice(0, event.srcElement.value.length-1)
    }
    const textData = event.srcElement.value.replace(/['`"“”]| |\n/gi, '').split(',').filter(x=>x!='').slice(0, 10);
    this.control.control.setValue(textData);
  }

  
}

