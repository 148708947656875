import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonHelperService } from 'src/app/services/common-helper.service';
import { commonDropdownConfig } from 'src/app/models/bank-payment.model';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-domain-dropdown',
  templateUrl: './domain-dropdown.component.html',
  styleUrls: ['./domain-dropdown.component.scss']
})
export class DomainDropdownComponent implements OnInit {

  domainListForm: FormGroup;
  domainNameList = [];
  configDomain: any;
  public configDropdown: commonDropdownConfig;
  @Output() domainData = new EventEmitter<string>();
  @Input() domainEvents: Observable<void>;
  
  constructor(private fb: FormBuilder,public commonHelper: CommonHelperService,) {     
    this.configDropdown = new commonDropdownConfig();//ngx-dropdown place holder values by lang 
    this.configDomain = this.configDropdown.configDomain;
  }

  ngOnInit() {
    this.domainListForm = this.fb.group({
      domainId: ['', Validators.required]
    });
    this.getDomaiDetails();

    this.domainListForm.get('domainId').valueChanges.subscribe(res => {
      this.domainData.emit(res);
    })

    this.domainEvents.subscribe(res => {
      this.domainListForm.get('domainId').patchValue('')
    });
  }

  getDomaiDetails(){
    const sendToken = {
      token: localStorage.getItem('authToken'),
      domainId: localStorage.getItem('accessSelfDomainOnly') == 'YES' ? localStorage.getItem('domainId') : 'ALL'
    };

    this.commonHelper.makeRequest(sendToken, 'getDomainList', true).subscribe(res => {
      if (res.statusCode == 0) {
        this.domainNameList = res.data;
        if (this.domainNameList.length == 1) {
          this.domainListForm.patchValue({ domainId: this.domainNameList[0] })
        }
      }
    });
  }

  get f() { return this.domainListForm.controls; }

}
