import { Directive, HostListener, ElementRef } from '@angular/core';


@Directive({
  selector: '[commissionValues]'
})
export class CommissionValuesDirective {

  constructor(private el: ElementRef) { }
  @HostListener('keypress', ['$event']) onKeyPress(event) {
    let e = <KeyboardEvent>event;
    const charCode = (e.which) ? e.which : e.keyCode;
    if ((charCode >= 48  && charCode <= 57 || charCode == 44) || e.key == localStorage.getItem("decimalCharacter")) {
      if (e.key == localStorage.getItem("decimalCharacter") && event.target.value.includes(localStorage.getItem("decimalCharacter"))){
        return false;
      }
      return true;
    }else{
      return false;
    }
  }
  // @HostListener('blur', ['$event'])
  // onBlur(event) {
  //   const textData = event.srcElement.value.trim();
  //   this.control.control.setValue( textData);
  // }
  
}
