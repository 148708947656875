import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchDomainPipe'
})
export class SearchDomainPipePipe implements PipeTransform {

  transform(array: any, query: string): any {


    if (query != undefined && query) {
      return array.filter(row => {
        if (!row.configDescription) {
          row.configDescription = ""
        }
        const domainId = row.domainId.toString().toLowerCase().includes(query.toString().toLowerCase())
        const domainName = row.name.toLowerCase().includes(query.toLowerCase())
        const createdAt = row.createdAt.toLowerCase().includes(query.toLowerCase())
        return ( domainId + domainName + createdAt);
      }
      )
    }
    return array;
  }
}
