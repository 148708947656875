import { Pipe, PipeTransform } from '@angular/core';
const padding = "000000";

@Pipe({
  name: 'currencyPipe'
})
export class CurrencyPipePipe implements PipeTransform {
    private prefix: string;
    private decimal_separator:string;
    private thousands_separator:string;
    private suffix:string;
  
    constructor(){
      this.prefix='';
      this.suffix='';
      this.decimal_separator=localStorage.getItem('decimalCharacter');
      this.thousands_separator = localStorage.getItem('thousandSeparator').toString()=='undefined'? ',':localStorage.getItem('thousandSeparator').toString();
    }
    transform(value: any,defaultValue:any, fractionSize:number = +localStorage.getItem('allowedDecimalDigits') ): string {
      if(!value && defaultValue=='2'){
        return "";
      }
      value=this.parse(value,fractionSize)
      if(parseFloat(value) % 1 != 0)
      {
        fractionSize = parseInt(localStorage.getItem('allowedDecimalDigits'));
      }
     
      let [ integer, fraction = ""] = ((value).toString() || "").split(localStorage.getItem('decimalCharacter'));
     
      integer = integer.split('$').join('')
      integer = integer.split('(').join('')
      fraction = fraction.split(')').join('')
    
      fraction = fractionSize > 0
        ? this.decimal_separator + (fraction+padding).substring(0, fractionSize) : "";
      integer = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.thousands_separator);
      if( isNaN(parseFloat(integer)) && defaultValue==0){
        integer="0"
      }else if(isNaN(parseFloat(integer)) || parseInt(integer)==0){
            integer = "0";
      }
     
      if(defaultValue  && parseInt(integer)==0 &&  fraction ==this.decimal_separator+'00' && parseInt(localStorage.getItem('allowedDecimalDigits'))>0 ){
        integer=defaultValue>1?'1':defaultValue;
      }
        return this.prefix + integer + fraction + this.suffix;
      
    }
  
  parse(value: string, fractionSize: number = +localStorage.getItem('allowedDecimalDigits')): string {
    let [integer, fraction = ""] = (value ? value.toString() : "").replace(this.prefix, "")
      .replace(this.suffix, "")
      .split(this.decimal_separator);
    integer = integer.toString().split(this.thousands_separator).join('');
    if (this.thousands_separator == " ") {
      var nbsp = String.fromCharCode(160);
      integer = integer.toString().split(nbsp).join('');
    }
    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
      ? '.' + (fraction + padding).substring(0, fractionSize)
      : "";
    return "" + integer + fraction;
  }

    convertValues(value){
      if(value && localStorage.getItem('decimalCharacter')==','){
        return value.toString().replace('.',',')
      }else return value;

    }
  
  }
