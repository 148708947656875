import { Injectable } from "@angular/core";

export interface Adapter<T> {
    adapt(item: any): T;
}

export class ResponseData {
    responseCode:number
    responseMessage:string
    responseData: CreditResultResponse
    constructor(response){
        this.responseCode= response.responseCode,
        this.responseMessage= response.responseMessage,
        this.responseData=  new CreditResultResponse(response.responseData)
    }
}
export class CreditResultResponse{
    data:CreditResult[];
    message:string
    statusCode:number
    constructor(data){
        this.data=<CreditResult[]>data.data,
        this.message = data.message,
        this.statusCode = data.statusCode
    }
}

export class CreditResult {
        constructor(
        public authorizedBy:any,
        public authorizedName:any,
        public billConfigId:any,
        public configDescription:any,
        public createdAt:any,
        public creditLimit:any,
        public orgId:any,
        public orgName:any,
        public remark:any,
        public requestId:any,
        public requestedBy:any,
        public requestedUserName:any,
        public status:any,
        public updatedAt:any){}
}

@Injectable({
    providedIn: 'root'
})
export class CreditResultAdapter implements Adapter<CreditResult> {

  adapt(item: any): CreditResult {
    return new CreditResult(
      item.authorizedBy,
      item.authorizedName,
      item.billConfigId,
      item.configDescription,
      item.createdAt,
      item.creditLimit,
      item.orgId,
      item.orgName,
      item.remark,
      item.requestId,
      item.requestedBy,
      item.requestedUserName,
      item.status,
      item.updatedAt
    );
  }
}


