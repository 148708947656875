import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'; 
@Component({
  selector: 'app-detail-inventory-modal',
  templateUrl: './detail-inventory-modal.component.html',
  styleUrls: ['./detail-inventory-modal.component.scss']
})
export class DetailInventoryModalComponent implements OnInit {
  detailInventoryData;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal(){
    this.activeModal.close();
  }

}
