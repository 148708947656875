import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTimeDirective]'
})
export class TimeDirectiveDirective {
@Input('appTimeDirective') value;
  constructor(public control: NgControl) { }
  @HostListener('keypress', ['$event']) onKeyPress(event) {
    if(event.target.value.length >1){
      return false;
      }
  }
  @HostListener('blur', ['$event']) onblur(event) {

    if(this.value){
      if(this.control.control.value > this.value){
        this.control.control.setValue(this.value);
    }
    }
    
    // if(this.value == 0 &&  this.control.control.value > 59){
    //     this.control.control.setValue('00');
    // }
  }


}
