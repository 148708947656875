import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, FormControl } from '@angular/forms';
import { CommonHelperService } from 'src/app/services/common-helper.service';
import { CustomValidators } from '../../directives/custom-validator';
import { Router, NavigationEnd } from '@angular/router';
import { IframeUrlService } from 'src/app/services/iframeUrlService';
declare var $:any; 
@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  changePasswordForm :FormGroup
  responseMessage: any;
  errorMessage: any;
  passwordRegex;
  passwordRegexMessage;
  messages="";
  navigationSubscription;
  showButton: Boolean = false;
  showEye: Boolean = false;
  setPasswordClass;
  showEyeConfirm:Boolean=false;
  showButtonConfirm:Boolean=false;
  passwordExpired = localStorage.getItem('forcePasswordUpdate');
  constructor(private fb:FormBuilder,private commonHelper:CommonHelperService,
    private router:Router, private iframeService:IframeUrlService) { 
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // console.log(e,this.router) 
     
    });
    if (!localStorage.getItem('authToken')) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.changePasswordForm=this.fb.group({
      oldPassword:['',CustomValidators.required],
      newPassword:['',CustomValidators.required],
      conNewPassword:['',CustomValidators.required]
    })
   this.iframeService.showMenuFromPasswordPage();
   this.iframeService.setIndex();

    if (localStorage.getItem('domainId')) {
      this.commonHelper.makeRequest({token: localStorage.getItem('authToken'), domainId: localStorage.getItem('accessSelfDomainOnly') == 'YES' ? localStorage.getItem('domainId') : 'ALL'}, 'getDomainList', false).subscribe(res => {
        if (res.statusCode == 0) {
          this.passwordRegex = res.data[0].PASSWORD_REGEX;
          [this.passwordRegexMessage, this.messages] = this.commonHelper.checkRegex(this.passwordRegex);
          // console.log(this.passwordRegexMessage)
        }

      })

    } else {
      this.commonHelper.getLoginData({token: localStorage.getItem('authToken')}).subscribe(res => {
        if (res.statusCode == 0) {
          localStorage.setItem('domainId', res.data.domainId)
          localStorage.setItem('accessSelfDomainOnly', res.data.accessSelfDomainOnly)
          localStorage.setItem('username', res.data.username)
          localStorage.setItem('orgTypeCode', res.data.orgTypeCode)
          localStorage.setItem('orgId', res.data.orgId)
          this.commonHelper.makeRequest({token: localStorage.getItem('authToken'), domainId: localStorage.getItem('accessSelfDomainOnly') == 'YES' ? localStorage.getItem('domainId') : 'ALL'}, 'getDomainList', false).subscribe(res => {
            if (res.statusCode == 0) {
              this.passwordRegex = res.data[0].PASSWORD_REGEX;
              [this.passwordRegexMessage, this.messages] = this.commonHelper.checkRegex(this.passwordRegex);
              // console.log(this.passwordRegexMessage)
            }

          })
        }
      })
  
    
  }
}
 

changePassword(){
  if(this.changePasswordForm.get('conNewPassword').value !=this.changePasswordForm.get('newPassword').value){
    this.errorMessage= this.commonHelper.getCustomMessages('new&ConfirmPasswordDoNotMatch');
    this.scrollAndShowMessage('containerWrap')
    return;
  }
 let request={
  "confirmNewPassword": this.changePasswordForm.get('conNewPassword').value,
  "newPassword": this.changePasswordForm.get('newPassword').value,
  "oldPassword": this.changePasswordForm.get('oldPassword').value,
  token:localStorage.getItem('authToken')
  }
  this.commonHelper.changePassword(request).subscribe(res=>{
      if(res.statusCode==0){
        this.responseMessage=res.message;
        if (localStorage.getItem('forcePasswordUpdate') == 'YES') {
          this.logout();
        } else {
          this.resetPage();
        }
      }
      else{
        this.errorMessage=res.message;

      }
    this.scrollAndShowMessage('containerWrap')
  })
}



resetPage(){
  this.changePasswordForm.reset('');
  this.ngOnInit();
  
}


scrollAndShowMessage(className){
  let el = $('.'+className);
  $('html,body').animate({scrollTop: (el.offset().top - 150)}, 'slow', () => {
    el.focus();
  });
  setTimeout(()=>{
    this.responseMessage=""
    this.errorMessage=""
  },3000)
}


get f() { return this.changePasswordForm.controls; }

ngOnDestroy() {
  if (this.navigationSubscription) { 
     this.navigationSubscription.unsubscribe();
  }
}
showPassword(type){
  if(type=='newPassword'){
    this.showButton = !this.showButton;
    this.showEye = !this.showEye;
  }else{
    this.showButtonConfirm = !this.showButtonConfirm;
    this.showEyeConfirm = !this.showEyeConfirm;
  }
}
  logout() {
    this.commonHelper.logOut({token: localStorage.getItem('authToken')}).subscribe(res => {
      let lang = localStorage.getItem('lang')
      localStorage.clear();
      localStorage.setItem('lang', lang)
      this.router.navigate(['login']);
    })
  }
  
}