declare var $: any;
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { CreditResult, CreditResultAdapter, ResponseData } from '../rms/user-mgmt/manage-credit-limits/credit-result';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class CommonHelperService {
  domain = environment.URL_Node;
  responseMessages = {};
  customMessages = {}
  private orgType = new BehaviorSubject({});
  currentorgType = this.orgType.asObservable();

  @BlockUI() blockUI: NgBlockUI;
  constructor(private http: HttpClient, private adapter: CreditResultAdapter, private router: Router) {
    this.prepareResponseJSON().subscribe(data => {
      this.responseMessages = data;
    });

    this.prepareCustomMessages().subscribe(data => {
      this.customMessages = data;
    });
  }
  getOrgType(value) {
    this.orgType.next(value);
  }

  makeRequest(data, url, loader): Observable<any> {
    if (loader) {
      this.blockUI.start('Loading...');
    }
    return this.http.post(this.domain + '/api/RMS/' + url, data).pipe(map(response => {
      return this.attachRespMessage(response);
    }))
  }

  prepareResponseJSON(): Observable<any> {
    return this.http.get("../../assets/responses/" + localStorage.getItem('lang') + ".json");
  }

  prepareCustomMessages(): Observable<any> {
    return this.http.get("../../assets/i18n/custom/" + localStorage.getItem('lang') + ".json");
  }

  getCustomMessages(key) {
    return this.customMessages[key]
  }

  attachRespMessage(response): any {
    if (this.responseMessages[response.statusCode])
      response.message = this.responseMessages[response.statusCode];
    return response;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
    if (!formGroup.valid) {
      let el = $('.ng-invalid:not(form):first');
      $('html,body').animate({ scrollTop: (el.offset().top - 150) }, 'slow', () => {
        el.focus();
      });
    }
  }

  validateAllFormFieldsIncludingFomrArray(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) { // control is a FormGroup
        this.validateAllFormFieldsIncludingFomrArray(control);
      } else { // control is a FormControl
        control.markAsTouched();
      }
    });
  }

  getBillDetail(data): Observable<any> {
    this.blockUI.start('Loading...');
    return this.http.post(this.domain + '/api/RMS/getBillDetail', data, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json; charset=utf-8')
    });
  }

  getChainTypeCode(chainTypeId, chainTypeList) {
    return chainTypeList.find(chainType => chainType.chainTypeId == chainTypeId).chainTypeCode;
  }

  getToken(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/get/token', data);
  }

  getMenuList(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getMenu', data);
  }

  getCountryList(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getCountryList', data);
  }

  getStateList(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getStateList', data);
  }

  getCityList(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getCityList', data);
  }
  getDomainList(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getDomainList', data);
  }

  getOrgTypeList(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getOrgTypeList', data);
  }

  getOrgList(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getOrgList', data);
  }


  getBillConfig(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getBillConfig', data);
  }

  checkUserName(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/checkUserName', data);
  }

  getLoginData(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getLoginData', data);
  }

  getUserDetails(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getUserDetails', data);
  }
  getUserAccessDetails(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getUserAccessDetails', data);
  }

  returnPagePermission(pageName): string[] {
    if (localStorage.getItem("permissions"))
      return JSON.parse(localStorage.getItem("permissions"))[pageName]
  }

  getServiceList(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getServiceList', data)

  }

  getGameList(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getGameList', data);

  }
  getTxnType(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getTxnType', data);

  }

  createCommissionRef(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/createCommissionRef', data);

  }

  getInvalidCommList(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getInvalidCommList', data);
  }
  getSaleReport(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getSaleReport', data);
  }

  getWinReport(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getWinReport', data);
  }

  getCreditConfigChangeRequestList(data): Observable<ResponseData> {
    return this.http.post<ResponseData>(this.domain + '/api/RMS/getCreditConfigChangeRequestList', data)
  }
  // .pipe(
  //   map((resultData:any)=> resultData.map(item=> this.adapter.adapt(item)))
  // );


  // pipe(
  //   // Adapt each item in the raw data array
  //   map((data: any[]) => data.map(item => this.adapter.adapt(item)))


  updateCreditConfig(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/updateCreditConfig', data);
  }

  logOut(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/logOut', data);
  }

  getBillTypes(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getBillTypes', data);

  }

  getEngineServices(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getEngineServices', data);
  }

  getLicenceDetails(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getLicenceDetails', data)
  }

  changePassword(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/changePassword', data)
  }

  getEngineUrlForPPL(data): Observable<any> {
    const token = encodeURIComponent(data.token)
    return this.http.get(data.domain + data.userName + '/' + data.userType + '/' + token + '/' + data.lang + '/' + data.orgId)
  }

  getEngineUrlForDGE(data): Observable<any> {
    const token = encodeURIComponent(data.token)
    return this.http.get(data.domain + data.userId + '/' + data.userType + '/' + token + '/' + data.lang + '/' + data.orgId + '/' + data.domainId)
  }


  getImmediateChildOrgList(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getImmediateChildOrgList', data);
  }

  getBoOrgs(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getBoOrgs', data)
  }


  getChainType(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getChainType', data);
  }


  getCommRef(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/getCommRef', data);
  }
  blockUser(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/blockUser', data);
  }

  unblockUser(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/unblockUser', data);
  }
  interval;
  animateMessage(that, className) {
    let el = $('.' + className);
    if (el[0]) {
      $('html,body').animate({ scrollTop: (el.offset().top - 150) }, 'slow', () => {
        el.focus();
        clearInterval(this.interval)
        this.interval = setInterval(() => {
          that.responseMessage = ""
          that.errorMessage = ""
          clearInterval(this.interval)
        }, 3000);
      });
    } else {
      $('html,body').animate({ scrollTop: (50) }, 'slow', () => {
        el.focus();
        clearInterval(this.interval)
        this.interval = setInterval(() => {
          that.responseMessage = ""
          that.errorMessage = ""
          clearInterval(this.interval)
        }, 3000);
      });
    }
  }
  animateModalMessage(that, className) {
    let el = $('.' + className);
    $('ngb-modal-window').animate({ scrollTop: (el.offset().top - 150) }, 'slow', () => {
      el.focus();
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        that.responseMessage = ""
        that.errorMessage = ""
        clearInterval(this.interval)
      }, 3000);
    });
  }

  clearMessages(that) {
    that.responseMessage = ""
    that.errorMessage = ""
  }

  resetPassword(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/resetPassword', data);
  }

  initiateForgotPassword(data): Observable<any> {
    return this.http.post(this.domain + '/api/RMS/initiateForgotPassword', data);
  }

  getRegionList(data): Observable<any> {
    this.blockUI.start('Loading...');
    return this.http.post(this.domain + '/api/RMS/getRegionList', data);
  }

  generatePDF(data): Observable<any> {
    this.blockUI.start('Loading...');
    return this.http.post(this.domain + '/api/RMS/pdfNode', data, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json; charset=utf-8')
    });
  }
  generateReceipts(data, url, loader): Observable<any> {
    if (loader) {
      this.blockUI.start('Loading...');
    }
    return this.http.post(this.domain + '/api/RMS/' + url, data, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json; charset=utf-8')
    });
  }

  private getErrorMessage = new BehaviorSubject('');
  currentMessage = this.getErrorMessage.asObservable();

  changeMessage(url: string) {
    this.getErrorMessage.next(url)
  }

  private loginHeaderLogoPath = new BehaviorSubject({});
  loginHeaderLogo = this.loginHeaderLogoPath.asObservable();
  private loginFooterLogoPath = new BehaviorSubject('');
  loginFooterLogo = this.loginFooterLogoPath.asObservable();
  private siteLogoPath = new BehaviorSubject({});
  siteLogo = this.siteLogoPath.asObservable();
  private infinityLogoPath = new BehaviorSubject('');
  infinityLogo = this.infinityLogoPath.asObservable();
  private poweredByLogoPath = new BehaviorSubject({});
  poweredByLogo = this.poweredByLogoPath.asObservable();

  setLogosConfiguration() {
    if (environment.CLIENT_CODE == 'ACDC') {
      this.loginHeaderLogoPath.next({ "logo": "../../../../assets/images/loginLogo.png", "tag": "IPT" })
      this.loginFooterLogoPath.next("")
      this.siteLogoPath.next({ "logo": "../../../../assets/images/siteLogo.png", "tag": "IPT", "imgWidth": "160px" })
      this.infinityLogoPath.next("")
      this.poweredByLogoPath.next({ "logo": "", "tag": "" });
    } else if (environment.CLIENT_CODE == 'SISAL') {
      this.loginHeaderLogoPath.next({ "logo": "../../../../assets/images/sisalLoginLogo.jpg", "tag": "SISAL" })
      this.loginFooterLogoPath.next("../../../../assets/images/hcmFooterBg.png")
      this.siteLogoPath.next({ "logo": "../../../../assets/images/siteLogoSisal.png", "tag": "SISAL", "imgWidth": "275px" })
      this.infinityLogoPath.next("../../../../assets/images/INFINITI_LimitlessGaming.png")
      this.poweredByLogoPath.next({ "logo": "../../../../assets/images/poweredBy.png", "tag": "" });
    } else if (environment.CLIENT_CODE == 'CAMEROON') {
      this.loginHeaderLogoPath.next({ "logo": "../../../../assets/images/CamWinLoginLogo1.png", "tag": "CAMEROON" })
      this.loginFooterLogoPath.next("../../../../assets/images/hcmFooterBg.png")
      this.siteLogoPath.next({ "logo": "../../../../assets/images/CamWinLoginLogo.png", "tag": "CAMEROON", "imgWidth": "200px" })
      this.infinityLogoPath.next("../../../../assets/images/INFINITI_LimitlessGaming.png")
      this.poweredByLogoPath.next({ "logo": "../../../../assets/images/poweredBy.png", "tag": "" });
    } else if (environment.CLIENT_CODE == 'CAMEROON_UAT') {
      this.loginHeaderLogoPath.next({ "logo": "../../../../assets/images/CamWinLoginLogoUat.png", "tag": "CAMEROON_UAT" })
      this.loginFooterLogoPath.next("../../../../assets/images/hcmFooterBg.png")
      this.siteLogoPath.next({ "logo": "../../../../assets/images/CamWinSiteLogoUat.png", "tag": "CAMEROON", "imgWidth": "200px" })
      this.infinityLogoPath.next("../../../../assets/images/INFINITI_LimitlessGaming.png")
      this.poweredByLogoPath.next({ "logo": "../../../../assets/images/poweredBy.png", "tag": "" });
    }
    else if (environment.CLIENT_CODE == 'ANDAMAN') {
      this.loginHeaderLogoPath.next({ "logo": "../../../../assets/images/andamanLoginLogo.png", "tag": "ANDAMAN" })
      this.loginFooterLogoPath.next("../../../../assets/images/hcmFooterBg.png")
      this.siteLogoPath.next({ "logo": "../../../../assets/images/andamanLogo1.png", "tag": "ANDAMAN", "imgWidth": "220px" })
      this.infinityLogoPath.next("../../../../assets/images/INFINITI_LimitlessGaming.png")
      this.poweredByLogoPath.next({ "logo": "../../../../assets/images/poweredBy.png", "tag": "" });
    }
    else if (environment.CLIENT_CODE == 'GELSA') {
      this.loginHeaderLogoPath.next({ "logo": "../../../../assets/images/gelsaLoginLogo.png", "tag": "GELSA" });
      this.loginFooterLogoPath.next("../../../../assets/images/hcmFooterBg.png");
      this.siteLogoPath.next({ "logo": "../../../../assets/images/gelsaSiteLogo1.png", "tag": "GELSA", "imgWidth": "220px" });
      this.infinityLogoPath.next("../../../../assets/images/INFINITI_LimitlessGaming.png");
      this.poweredByLogoPath.next({ "logo": "../../../../assets/images/poweredBy.png", "tag": "" });
    }
    else if (environment.CLIENT_CODE == 'PAYPR') {
      this.loginHeaderLogoPath.next({ "logo": "../../../../assets/images/payprLoginLogo.png", "tag": "PAYPR" })
      this.loginFooterLogoPath.next("../../../../assets/images/payprFooter.png")
      this.siteLogoPath.next({ "logo": "../../../../assets/images/payprSiteLogo1.png", "tag": "PAYPR", "imgWidth": "220px" })
      this.infinityLogoPath.next("../../../../assets/images/INFINITI_LimitlessGaming.png")
      this.poweredByLogoPath.next({ "logo": "../../../../assets/images/poweredBy1.png", "tag": "" });
    }
    else if (environment.CLIENT_CODE == 'UNL') {
      this.loginHeaderLogoPath.next({ "logo": "../../../../assets/images/unlLoginLogo.png", "tag": "UNL" })
      this.loginFooterLogoPath.next("../../../../assets/images/hcmFooterBg.png")
      this.siteLogoPath.next({ "logo": "../../../../assets/images/unlSiteLogo1.png", "tag": "UNL", "imgWidth": "220px" })
      this.infinityLogoPath.next("../../../../assets/images/INFINITI_LimitlessGaming.png")
      this.poweredByLogoPath.next({ "logo": "../../../../assets/images/poweredBy1.png", "tag": "" });
    } 
    else if (environment.CLIENT_CODE == 'SAPID_BLUE') {
      this.loginHeaderLogoPath.next({ "logo": "../../../../assets/images/rapidBlueLoginLogo.png", "tag": "SAPID_BLUE" })
      // this.loginFooterLogoPath.next("../../../../assets/images/hcmFooterBg.png")
      this.siteLogoPath.next({ "logo": "../../../../assets/images/rapidBluesiteLogo1.png", "tag": "SAPID_BLUE", "imgWidth": "220px" })
      // this.infinityLogoPath.next("../../../../assets/images/INFINITI_LimitlessGaming.png")
      // this.poweredByLogoPath.next({ "logo": "../../../../assets/images/poweredBy1.png", "tag": "" });
    } 
    else {
      this.loginHeaderLogoPath.next({ "logo": "../../../../assets/images/skilrockLoginLogo.png", "tag": "SISAL" })
      this.loginFooterLogoPath.next("../../../../assets/images/hcmFooterBg.png")
      this.siteLogoPath.next({ "logo": "../../../../assets/images/siteLogo1.1.png", "tag": "SISAL", "imgWidth": "220px" })
      this.infinityLogoPath.next("../../../../assets/images/INFINITI_LimitlessGaming.png")
      this.poweredByLogoPath.next({ "logo": "../../../../assets/images/poweredBy.png", "tag": "" });
    }
  }

  private pageMenuTitle = new BehaviorSubject('');
  pageCurrentTitle = this.pageMenuTitle.asObservable();
  private menuArray = new BehaviorSubject([]);
  menuTitleArray = this.menuArray.asObservable();

  setPageTitle(title) {
    this.pageMenuTitle.next(title);
  }
  setMenuArray(array) {
    this.menuArray.next(array);

  }

  allowURL = ['/change_password','/RMS/dashboard', '/RMS/unauthorized', '/login', '/forgotPassword', '/RMS/PPL', '/RMS/DGE', '/RMS/SLE', '/RMS/SBS', '/RMS/VSE'];
  currentURl(url) {
    this.menuTitleArray.subscribe(res => {
      if (res)
        var caption = res.filter(data => data.relativePath == url)
      if (caption.length) {
        this.pageMenuTitle.next(caption[0].caption)
      } else {
        if (this.allowURL.filter(allowedUrl => allowedUrl == url).length) {
          this.pageMenuTitle.next('')
        }
        else {
          this.router.navigate(['/RMS/dashboard'])
        }
      }

    });

  }


  requestDateFormat(date): string {
    let stringDate: string = "";
    if (date) {
      let stringDay = isNumber(date.day) ? padNumber(date.day) : "";
      let stringMonth = isNumber(date.month) ? padNumber(date.month) : "";
      let stringYear = isNumber(date.year) ? (date.year - 0).toString() : "";
      stringDate = stringYear + '-' + stringMonth + '-' + stringDay;
    }
    return stringDate;

  }


  private userName = new BehaviorSubject('');
  currentUserName = this.userName.asObservable();
  setUserName(name) {
    this.userName.next(name)
  }


  checkRegex(regex) {
    // console.log(regex)
    if (regex == '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,16}$' || regex == '^((?=.*?[А-ЩЬЮЯҐЄІЇ])(?=.*?[а-щьюяґєії])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])).{8,16}$') {
      return [this.getCustomMessages("pwdAlphanumeric"),
      `<li>` + this.getCustomMessages("oneUpperCase") + `</li><li>` + this.getCustomMessages("oneDigit") + `(0-9)</li><li>` + this.getCustomMessages("atLeastOneLowerCase") + `</li><li>` + this.getCustomMessages("minLength") + ` </li> <li> ` + this.getCustomMessages("maxLength") + ` </li>`]
    } else if (regex == '^[0-9]{8,16}$') {
      // console.log("fdfds")
      return [this.getCustomMessages("onlyNumbersAllow"),
      `<li> ` + this.getCustomMessages("onlyNumbers") + ` </li><li>` + this.getCustomMessages("minLength") + `</li> <li>` + this.getCustomMessages("maxLength") + ` </li>`]
    } else if (regex == '^[0-9]{5,5}$') {
      // console.log("fdfds")
      return [this.getCustomMessages("onlyNumbersAllow"),
      `<li>` + this.getCustomMessages("onlyNumbers") + `</li><li>` + this.getCustomMessages("minLengthFive") + ` </li> <li>` + this.getCustomMessages("maxLengthFive") + `</li>`]
    } else {
      return ['', regex]
    }
  }

  private resetOrgChain = new BehaviorSubject(false);
  shouldResetOrgChain = this.resetOrgChain.asObservable();
  callNgOnInit(data) {
    this.resetOrgChain.next(data)
  }


  private isDataToBePassedToDifferentComponent = new BehaviorSubject({ domainId: '', orgTypeCode: '', orgTypeCodeName: '', orgId: '', orgName: '' });
  dataToBePassedToDifferentComponent = this.isDataToBePassedToDifferentComponent.asObservable();

  setDataToBePassedToDifferentComponent(data) {
    this.isDataToBePassedToDifferentComponent.next(data);
  }



  roleList = new Subject();
  getRoleList(data) {
    this.makeRequest(data, 'getRoleList', false).subscribe(res => {
      if (res.statusCode == 0) {
        this.roleList.next(res.data);
      } else {
        this.roleList.next([]);
      }
    })
  }

  makeRequired(formControl, fieldName) {
    for (let i = 0; i < fieldName.length; i++) {
      formControl.controls[fieldName[i]].setValidators([Validators.required]);
      formControl.controls[fieldName[i]].updateValueAndValidity({ emitEvent: false })
    }
  }

  setFieldValues(form, fieldName, value) {
    for (let i = 0; i < fieldName.length; i++) {
      form.get([fieldName[i]]).patchValue(value[i]);
    }
  }

  disableField(form, fieldName) {
    form.get([fieldName]).disable({ emitEvent: false });
  }

  clearValidators(formName, arr) {
    for (var i = 0; i < arr.length; i++) {
      if (this[formName] && this[formName].controls[arr[i]]) {
        this[formName].controls[arr[i]].clearValidators();
        this[formName].controls[arr[i]].updateValueAndValidity();
      }
    }
  }

  enableField(form, fieldName) {
    form.get([fieldName]).enable({ emitEvent: false });
  }

  setValidation(formName, validityObjectArr) {
    for (var i = 0; i < validityObjectArr.length; i++) {
      if (
        this[formName] &&
        this[formName].controls[validityObjectArr[i].fieldName]
      ) {
        this[formName].controls[validityObjectArr[i].fieldName].setValidators(
          validityObjectArr[i].validators
        );
        this[formName].controls[
          validityObjectArr[i].fieldName
        ].updateValueAndValidity();
      }
    }
  }

  resetValidation(formName, resetValidityArr) {
    for (var i = 0; i < resetValidityArr.length; i++) {
      if (
        this[formName] &&
        this[formName].controls[resetValidityArr[i].fieldName]
      ) {
        this[formName].controls[
          resetValidityArr[i].fieldName
        ].clearValidators();
        this[formName].controls[
          resetValidityArr[i].fieldName
        ].updateValueAndValidity();
        this[formName].controls[resetValidityArr[i].fieldName].setValidators(
          resetValidityArr[i].validators
        );
        this[formName].controls[
          resetValidityArr[i].fieldName
        ].updateValueAndValidity();
      }
    }
  }

  private MOBILECODE = new BehaviorSubject('');
  getMOBILECODE = this.MOBILECODE.asObservable();

  setMobileCode(mobileCode) {
    this.MOBILECODE.next(mobileCode);
  }

  private MOBILE_CODE_MIN_MAX_LENGTH = new BehaviorSubject('');
  getMOBILE_CODE_MIN_MAX_LENGTH = this.MOBILE_CODE_MIN_MAX_LENGTH.asObservable();

  setMobileCodeMinMax(mobileCodeMinMax) {
    this.MOBILE_CODE_MIN_MAX_LENGTH.next(mobileCodeMinMax);
  }
  private dataUpdatedSource = new Subject<void>();
dataUpdated$ = this.dataUpdatedSource.asObservable();
 notifyDataUpdated() {
    this.dataUpdatedSource.next();
  }

  private SYSTEM_ALLOWED_LANGUAGES = new BehaviorSubject('');
  getSYSTEM_ALLOWED_LANGUAGES = this.SYSTEM_ALLOWED_LANGUAGES.asObservable();
  
  setAllowdedLanguages(allowdedLang){
    this.SYSTEM_ALLOWED_LANGUAGES.next(allowdedLang);
  }

  changeByLang(value){
    let valueByLang = this.getCustomMessages(value.toLowerCase()); 
    return valueByLang?valueByLang:value;
  }
}

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return "";
  }
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}


