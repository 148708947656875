import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { CommonHelperService } from 'src/app/services/common-helper.service';
import { Router, NavigationEnd } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-org-pos-assign',
  templateUrl: './org-pos-assign.component.html',
  styleUrls: ['./org-pos-assign.component.scss']
})
export class OrgPosAssignComponent implements OnInit {
  orgTypeList = [];
  orgPosAssignForm: FormGroup;
  childSelectName;
  posTypeList;
  posList;
  domainList = [];
  appList = [];
  appTypeList = [];
  boOrgId:any;
  loggedInOrgType = localStorage.getItem("orgTypeCode");
  navigationSubscription
  @Input() isOrgRegistration;
  @Input() orgType
  @Input() orgName
  dropdownSettings = {
    enableCheckAll: true,
    text: "Select",
    primaryKey: 'posTypeId',
    labelKey: 'posTypeName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: false,
    classes: "myclass custom-class",
    // lazyLoading: true,
    selectGroup: true,
  };
  dropdownSettingsForModel = {
    enableCheckAll: true,
    text: "Select",
    primaryKey: 'modelId',
    labelKey: 'modelName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: false,
    classes: "myclass custom-class",
    // lazyLoading: true,
    selectGroup: true,
  };
  @Output() onReset=new EventEmitter();
  warningMessage = '';
  constructor(private fb: FormBuilder,
    private commonHelper: CommonHelperService,private router:Router) {
      this.navigationSubscription = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          if(this.isOrgRegistration){
            this.onReset.emit(true)
          }
          if (this.orgPosAssignForm) {
            this.resetPage();
          }
        }
      });

  }

  get domain() {
    return this.orgPosAssignForm.get('domain');
  }

  get organizationType() {
    return this.orgPosAssignForm.get('organizationType');
  }

  configOrgType = {
    displayKey:"orgName",
    search:true, 
    height: 'auto', 
    placeholder:'Select',              
    customComparator: ()=>{},
    noResultsFound: 'No results found!',
    clearOnSelection: true,
    searchOnKey: 'orgName'
  };

  configDomain = {
    displayKey:"domainName",
    search:true, 
    height: 'auto', 
    placeholder:'Select',              
    customComparator: ()=>{},
    noResultsFound: 'No results found!',
    clearOnSelection: true,
    searchOnKey: 'domainName'
  };

  setSelectName(childType) {
    this.orgPosAssignForm.patchValue({ childOrgId: '' })
    switch (childType) {
      case "RET":
        this.childSelectName = this.commonHelper.getCustomMessages("retailer")
        break;
      case "MAGT":
        this.childSelectName = this.commonHelper.getCustomMessages("masterAgent")
        break;
      case "SAGT":
        this.childSelectName = this.commonHelper.getCustomMessages("subAgent")
        break;
      case "AGT":
        this.childSelectName = this.commonHelper.getCustomMessages("agent")
        break;
    }
    return this.childSelectName;
  }

  ngOnInit() {
    this.orgPosAssignForm = this.fb.group({
      organizationType: ['', Validators.required],
      domain: ['', Validators.required],
      childOrgId: ['', Validators.required],
      appTypeId: ['', Validators.required],
      appId: ['', Validators.required],
      posType: ['', Validators.required],
      modelArray: this.fb.array([])
    })

    this.commonHelper.getDomainList({ token: localStorage.getItem('authToken'), domainId: localStorage.getItem('accessSelfDomainOnly') == 'YES' ? localStorage.getItem('domainId') : 'ALL' }).subscribe(res => {
      if (res.statusCode == 0) {
        this.domainList = res.data;
        if(this.domainList.length == 1){
          this.orgPosAssignForm.patchValue({ domain: this.domainList[0] });
          this.commonHelper.disableField(this.orgPosAssignForm,'domain');
        }
      }
    });


    this.domain.valueChanges.subscribe(domainId => {
      this.orgPosAssignForm.patchValue({ organizationType: '' })
      this.orgPosAssignForm.patchValue({ childOrgId: '' })
      this.orgPosAssignForm.patchValue({ appTypeId: '' })
      this.orgPosAssignForm.patchValue({ appId: '' })
      this.orgPosAssignForm.patchValue({ posType: '' })
      if(domainId && domainId.domainId) {
      this.orgTypeList = [];
      this.appTypeList = [];
      this.posTypeList = [];
      this.appList = [];
      if(this.loggedInOrgType == 'SUPER_BO'){
        this.boOrgId = [];
        const req = {
          domainId: this.domain.value.domainId,
          token: localStorage.getItem('authToken')
        }
        this.commonHelper.makeRequest(req, 'getBoOrgs', false).subscribe(res => {
          if (res.statusCode == 0) {
            this.boOrgId = res.data;
          }
        })
      }
    if (this.isOrgRegistration) {
      this.orgTypeList.push(this.orgType[0])
      this.organizationType.patchValue(this.orgTypeList[0].orgTypeCode)
      this.organizationType.disable({ emitEvent: false });
      this.orgList.push(this.orgName[0])
      this.setSelectName(this.orgTypeList[0].orgTypeCode);
      this.showChildField = true;
      this.orgPosAssignForm.get('childOrgId').patchValue(this.orgList[0])
      this.orgPosAssignForm.get('childOrgId').disable({ emitEvent: false })
    } else {
      const request = {
        token: localStorage.getItem('authToken'),
        domainId: this.domain.value.domainId,                     //localStorage.getItem('domainId')
      }
      this.commonHelper.makeRequest(request, 'getOrgTypeList', false).subscribe(res => {
        this.orgTypeList = [];
        if (res.statusCode == 0) {
          for (let key in res.data) {
            if (key != localStorage.getItem('orgTypeCode')) {
              switch (localStorage.getItem('orgTypeCode')) {
                case 'SUPER_BO':
                  if (key == 'MAGT' || key == 'RET')
                    this.orgTypeList.push({ orgTypeCode: key, orgTypeName: res.data[key] })
                  break;
                case 'BO':
                  if (key == 'MAGT' || key == 'RET')
                    this.orgTypeList.push({ orgTypeCode: key, orgTypeName: res.data[key] })
                  break;
                case 'MAGT':
                  if (key == 'AGT' || key == 'RET')
                    this.orgTypeList.push({ orgTypeCode: key, orgTypeName: res.data[key] })
                  break;
                case 'AGT':
                  if (key == 'SAGT' || key == 'RET')
                    this.orgTypeList.push({ orgTypeCode: key, orgTypeName: res.data[key] })
                  break;
                case 'SAGT':
                  if (key == 'RET')
                    this.orgTypeList.push({ orgTypeCode: key, orgTypeName: res.data[key] })
                  break;

              }
            }
          }
          if (this.orgTypeList.length == 1) {
            this.organizationType.patchValue(this.orgTypeList[0].orgTypeCode)
            this.organizationType.disable({ emitEvent: false })
          }
        }
      })

      this.organizationType.valueChanges.subscribe(chainCode => {
        if (chainCode) {
          this.setSelectName(chainCode);
          let data = {
            token: localStorage.getItem('authToken'),
            orgTypeCode: chainCode,
            searchedOrgTypeCode: this.organizationType.value,
            domainId: this.domain.value.domainId,                         //localStorage.getItem('domainId'),
            parentOrgId: localStorage.getItem('orgId')
          };
          if(this.loggedInOrgType == 'SUPER_BO'){
            data.parentOrgId = this.boOrgId;
          }
          this.commonHelper.makeRequest(data, 'getImmediateChildOrgList', false).subscribe(res => {
            if (res.statusCode == 0) {
              this.showChildField = true;
              this.orgList = [];
              this.orgList = res.data;
            } else {
              this.errorMessage = res.message;
              this.orgList = [];
              this.commonHelper.animateMessage(this, "containerWrap");
            }
          })
        } else {
          this.showChildField = false;
        }
      })
    }

    this.getAppTypeList();
    this.orgPosAssignForm.get('appTypeId').valueChanges.subscribe(appTypeId => {
      this.posTypeList = [];
      this.appList = [];
      this.orgPosAssignForm.get('posType').patchValue('')
      this.orgPosAssignForm.get('appId').patchValue('');
      if (appTypeId) {
        this.commonHelper.makeRequest({ token: localStorage.getItem('authToken'), domainId: this.domain.value.domainId, appTypeId: parseInt(appTypeId)}, 'getApp', false).subscribe(res => {
          if (res.statusCode == 0) {
            this.appList = res.data;
          } else {
            this.errorMessage = res.message;
            this.commonHelper.animateMessage(this, "containerWrap");
          }
        });
        let data = { token: localStorage.getItem('authToken'), domainId: this.domain.value.domainId };
        this.commonHelper.makeRequest(data, 'getPosType', false).subscribe(res => {
          if (res.statusCode == 0) {
            this.posTypeList = res.data;
          } else {
            this.errorMessage = res.message;
            this.commonHelper.animateMessage(this, "containerWrap");
          }
        });
      }else{
        this.appList = [];
      }
    });    
    }   
    });
  }

  getAppTypeList(){
    this.appTypeList = [];
    this.commonHelper.makeRequest({ token: localStorage.getItem('authToken') }, 'getAppTypeList', false).subscribe(res => {
      if (res.statusCode == 0) {
        this.appTypeList = res.data.filter(function (a) {
          return a.appType != "Web_Panel";
        });
      } else {
        this.errorMessage = res.message;
        this.commonHelper.animateMessage(this, "containerWrap");
      }
    });
  }

  onSelectPosType(event) {
    let control = this.orgPosAssignForm.get('modelArray') as FormArray;
    if (event.posTypeId) {
      var reqObj = {
        "token": localStorage.getItem('authToken'),
        "posTypeId": event.posTypeId,
        "domainId": parseInt(this.domain.value.domainId),
        "traceableModel": "YES"
      }
      this.commonHelper.makeRequest(reqObj, 'getPosTypeModel', false).subscribe(res => {
        if (res.statusCode == 0) {
          control.push(this.fb.group({
            modelSelected: ['', Validators.required],
            posTypeName: [event.posTypeName],
            modelList: [res.data],
            posTypeId: [event.posTypeId],
            modelWithSerialNumArray: this.fb.array([])
          }))
        } else {
          this.warningMessage = `No Models available for ${event.posTypeName}`;
          let selected = this.orgPosAssignForm.get('posType').value;
              selected = selected.filter((o) => {
                return o.posTypeId != reqObj.posTypeId;
              })
          this.orgPosAssignForm.get('posType').patchValue(selected);
          this.animateMessage(this, "containerWrap");
        }
      })
    }
  }
  onDeSelectPosType(event) {
    let control = this.orgPosAssignForm.get('modelArray') as FormArray;
    let data = control.controls.filter(element => (<FormGroup>element).controls.posTypeId.value != event.posTypeId)
    while (control.length) {
      control.removeAt(0);
    }
    control.controls = data
  }
  onDeSelectAllPosType(event) {
    let control = this.orgPosAssignForm.get('modelArray') as FormArray;
    while (control.length) {
      control.removeAt(0);
    }
  }
  onSelectAllPosType(event) {
    this.onDeSelectAllPosType(event)
    // console.log(event)
    event.forEach((element, index) => {
      this.onSelectPosType(element)
    });
  }




  onSelectModel(event, index) {
    let arrayControl = this.orgPosAssignForm.get('modelArray') as FormArray;
    let groupControl = arrayControl.at(index) as FormGroup;
    let modelWithSerialNumArray = groupControl.get('modelWithSerialNumArray') as FormArray;
    if (event.posTypeId) {
      var reqObj = {
        "token": localStorage.getItem('authToken'),
        "modelId": event.modelId,
        "domainId" : this.domain.value.domainId,
        "orgRegistration": "YES"
      }
      modelWithSerialNumArray.push(this.fb.group({
        serialNumberArr: this.fb.array([]),
        modelId: [event.modelId],
        posId: [event.id],
        modelName: [event.modelName],
        serialNumberError: [false],
        selectAll: []
      }))
      let control = modelWithSerialNumArray.at(modelWithSerialNumArray.length - 1) as FormGroup;
      this.commonHelper.makeRequest(reqObj, 'getModelSerial', false).subscribe(res => {
        if (res.statusCode == 0) {
          res.data[0].terminals.forEach(element => {
            (<FormArray>control.get('serialNumberArr')).push(this.fb.group({
              serialNumber: [element],
              selectedSerialNumber: [false],
              modelId: [event.modelId],
              posId: [event.id],
            }))
          });
        }
        else {
          this.errorMessage = res.message;
          this.commonHelper.animateMessage(this, "containerWrap");
        }
      })
    }
  }

  onDeSelectModel(event, index) {
    let arrayControl = this.orgPosAssignForm.get('modelArray') as FormArray;
    let groupControl = arrayControl.at(index) as FormGroup;
    let control = groupControl.get('modelWithSerialNumArray') as FormArray;
    // let errorControl = groupControl.get('errorArray') as FormArray;
    // let data1 = errorControl.controls.filter(element => (<FormGroup>element).controls.modelId.value != event.modelId)
    let data = control.controls.filter(element => (<FormGroup>element).controls.modelId.value != event.modelId)
    while (control.length) {
      control.removeAt(0);
    }
    // errorControl.controls = data1;
    control.controls = data


    // console.log(arrayControl)
  }
  onDeSelectAllModel(event, index) {
    // console.log(event)
    let arrayControl = this.orgPosAssignForm.get('modelArray') as FormArray;
    let groupControl = arrayControl.at(index) as FormGroup;
    let control = groupControl.get('modelWithSerialNumArray') as FormArray;
    while (control.length) {
      control.removeAt(0);
    }
  }
  onSelectAllModel(event, index) {
    this.onDeSelectAllModel(event, index)
    event.forEach((element) => {
      this.onSelectModel(element, index)
    });
  }

  showChildField = false;
  orgList = [];
  errorMessage;
  responseMessage;
  modelList = [];

  assignOrgPos() {
    if (this.orgPosAssignForm.valid) {
      let arrayControl = this.orgPosAssignForm.get('modelArray') as FormArray;
      let requestData = [];
      let sendRequest = true;
      arrayControl.controls.forEach((element, index) => {
        let posType = element.get('posTypeId').value
        let control = element.get('modelWithSerialNumArray') as FormArray;
        let posData = []
        control.controls.forEach(modelWithSerial => {
          let isSerialError = true;
          let pos = {
            posId: modelWithSerial.get('posId').value,
            modelId: modelWithSerial.get('modelId').value,
            serialNo: []
          };
          (<FormArray>modelWithSerial.get('serialNumberArr')).controls.forEach(element => {
            if (element.get('selectedSerialNumber').value) {
              isSerialError = false;
              pos.serialNo.push(element.get('serialNumber').value)
            }
          });
          if ((<FormArray>modelWithSerial.get('serialNumberArr')).controls.length && isSerialError) {
            modelWithSerial.get('serialNumberError').patchValue(true)
            sendRequest = false;
            return;
          }
          else {
            modelWithSerial.get('serialNumberError').patchValue(false)
          }
          posData.push(pos)

        });
        let el = $('.serialNumberError');
        if (el.length)
          $('html,body').animate({ scrollTop: (el.offset().top - 150) }, 'slow', () => {
            el.focus();
          });

        let data = {
          posTypeId: posType,
          pos: posData
        }
        requestData.push(data)
      });
      // console.log(requestData)
      let data = {
        token: localStorage.getItem('authToken'),
        domainId: this.domain.value.domainId,
        appId: this.orgPosAssignForm.get('appId').value,
        orgId: this.orgPosAssignForm.get('childOrgId').value.orgId,
        posArray: requestData
      }

      if (sendRequest) {
        this.commonHelper.makeRequest(data, 'assignOrgPos', false).subscribe(res => {
          if (res.statusCode === 0) {
            this.responseMessage = res.message;
          } else {
            this.errorMessage = res.message;
          }
          this.commonHelper.animateMessage(this, "containerWrap");
        });
      }
    } else {
      this.commonHelper.validateAllFormFieldsIncludingFomrArray(this.orgPosAssignForm);
      if (!this.orgPosAssignForm.valid) {
        let el = $('.ng-invalid:not(form):first');
        $('html,body').animate({ scrollTop: (el.offset().top - 150) }, 'slow', () => {
          el.focus();
        });
      }
    }
  }


  selectAll(selectedModal) {
    let serialNumArr = selectedModal.get('serialNumberArr') as FormArray;
    if (!selectedModal.get('selectAll').value) {
      serialNumArr.controls.forEach(element => {
        element.get('selectedSerialNumber').patchValue(true)
      });
    } else {
      serialNumArr.controls.forEach(element => {
        element.get('selectedSerialNumber').patchValue(false);
      });
    }
  }
  resetPage() {
      this.ngOnInit();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

  }
  interval;
  animateMessage(that, className) {
    let el = $('.' + className);
    $('html,body').animate({ scrollTop: (el.offset().top - 150) }, 'slow', () => {
      el.focus();
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        that.warningMessage = '';
        clearInterval(this.interval)
      }, 3000);
    });
  }
}
