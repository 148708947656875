import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonHelperService } from 'src/app/services/common-helper.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-payout-modal',
  templateUrl: './confirm-payout-modal.component.html',
  styleUrls: ['./confirm-payout-modal.component.scss']
})
export class ConfirmPayoutModalComponent implements OnInit {

  modalHeader;
  data;
  errorMessage = '';
  responseMessage = '';
  confirmPayoutForm: FormGroup;
  modalData;
  orgType;
  disableButton;
  constructor(public modal: NgbActiveModal, private commonHelper: CommonHelperService, private fb: FormBuilder) { }

  ngOnInit() {
    this.confirmPayoutForm = this.fb.group({
      remark: ['', Validators.required],
    })
  }
  closeModal() {
    this.modal.close(this.data);
  }
  onSave() {
    if (this.confirmPayoutForm.valid) {
      let request = {
        token: localStorage.getItem('authToken'),
        requestId: this.data.requestId,
        status: "PAYMENT_DONE",
        orgTypeCode: this.orgType,
        remark: this.confirmPayoutForm.get('remark').value
      }
      this.commonHelper.makeRequest(request, 'updatePayoutBankRequestStatus', true).subscribe(res => {
        if (res.statusCode == 0) {
          this.data = res.data;
          this.responseMessage = res.message;
        } else {
          this.errorMessage = res.message;
        }
        this.commonHelper.animateModalMessage(this, "containerWrap");
      })
    } else {
      this.commonHelper.validateAllFormFields(this.confirmPayoutForm)
    }
  }
}

