import { Pipe, PipeTransform } from '@angular/core';
const padding = "000000";

@Pipe({
  name: 'commissionPipe'
})
export class CommissionPipePipe implements PipeTransform {
    private prefix: string;
    private decimal_separator:string;
    private thousands_separator:string;
    private suffix:string;
  
    constructor(){
      this.prefix='';
      this.suffix='';
      this.decimal_separator=localStorage.getItem('decimalCharacter');
      this.thousands_separator = '';
    }
    transform(value: any,defaultValue:any, fractionSize:number = +localStorage.getItem('allowedDecimalDigits') ): string {
      if(parseFloat(value) % 1 != 0)
      {
        fractionSize = parseInt(localStorage.getItem('allowedDecimalDigits'));
      }
      let [ integer, fraction = ""] = (parseFloat(value).toString() || "").toString().split(".");
      fraction = fractionSize > 0
        ? this.decimal_separator + (fraction+padding).substring(0, fractionSize) : "";
      integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousands_separator);
      if(isNaN(parseFloat(integer)))
      {
            integer = "0";
      }
      if(defaultValue && integer=='0'){
        integer=defaultValue
      }
        return this.prefix + integer + fraction + this.suffix;
      
    }
  
    parse(value: string, fractionSize: number = +localStorage.getItem('allowedDecimalDigits') ): string {
      let [ integer, fraction = "" ] = (value || "").replace(this.prefix, "")
                                                    .replace(this.suffix, "")
                                                    .split(this.decimal_separator);
  
      integer = integer.replace(new RegExp(this.thousands_separator, "g"), "");
  
      fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
        ? this.decimal_separator + (fraction + padding).substring(0, fractionSize)
        : "";
  
      return integer + fraction;
    }
  
  }
